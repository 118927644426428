import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Input, InputNumber, Modal, notification, DatePicker, Spin, Select, Empty, Table, Image, Carousel, Tag, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import defaultImage from '../../images/user.png';
import { searchSku, searchActiveSkus, searchAllSkus } from "../../redux/actions/inventoryActive.action";
import "../../css/inventory.scss"
const { Option } = Select;
const { TextArea } = Input;

export default function Active() {
    const dispatch = useDispatch();

    const { getInventoryProducts } = useSelector((state) => {
        return state;
    });

    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataDetails, setDataDetail] = useState("");
    const [filterSKU, setFilterSKU] = useState();
    const [filterTitle, setFilterTitle] = useState();
    const [searchSKUs, setSearchSKUs] = useState([]);
    const [searchTitles, setSearchTitles] = useState([]);
    const [tempSKU, setTempSKU] = useState();
    const [tempTitle, setTempTitle] = useState();
    const [skuLoad, setSkuLoad] = useState(false);
    const [titleLoad, setTitleLoad] = useState(false);


    const limit = 50;

    useEffect(() => {
        dispatch(searchSku(1, limit)).then((res) => {
            if (res.success) {
                setLoading(false);
            }
            else {
                console.log(res, "error");
                setLoading(false);
            }
        })
    }, []);

    //   const handlePageClick = (e) => {
    //     console.log("e", e);
    //     const page = e.selected;
    //     setCurrentPage(page);
    //     setLoadd(true);
    //     setLoading(true);
    //     dispatch(getBoostedReviews(page + 1, limit, startDate, endDate, influencerId)).then((res) => {
    //       setLoading(false);
    //       setLoadd(false)
    //       setInfluencer("");
    //       setTotalEvent(res?.message?.total_records);
    //     });
    //   };

    //   const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     setGroupByTable(banType)
    //     setStatus(true);
    //     dispatch(getBoostedReviews(currentPage + 1, limit, startDate, endDate, influencerId)).then((res) => {
    //       setLoading(false);
    //       setInfluencer("");
    //       setTotalEvent(res?.message?.total_records);
    //       setCurrentPage(0);
    //     });
    //     // setSubmit(groupBy);
    //   };

    const getStatus = (record) => {
        let status = record?.variants?.reduce((acc, item) => {
            return (
                acc = acc + item.inventory_quantity
            )
        }, 0)
        if (status > 70) {
            return (
                <div className='status-info'><div className='status-flag green'></div>
                    {/* <p>green</p> */}
                </div>
            )
        } else if (status >= 50 && status <= 70) {
            return (
                <div className='status-info'><div className='status-flag yellow'></div>
                    {/* <p>yellow</p> */}
                </div>
            )
        } else if (status < 50) {
            return (
                <div className='status-info'><div className='status-flag red'></div>
                    {/* <p>red</p> */}
                </div>
            )
        }
        else {
            return null
        }
    }

    const showDetailModal = (s) => {
        setDataDetail(s);
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (tempSKU) {

            const getData = setTimeout(() => {
                setSkuLoad(true)
                dispatch(searchAllSkus({ sku: tempSKU })).then((res) => {
                    setSkuLoad(false)
                    if (res?.success) {
                        setSearchSKUs(res?.message)
                    }
                })
            }, 1000)

            return () => clearTimeout(getData)
        }
    }, [tempSKU])



    const handleReset = (res) => {
        setLoading(true);
        setTempSKU();
        setTempTitle();
        setFilterSKU();
        setFilterTitle();
        dispatch(searchSku(1, limit)).then((res) => {
            if (res.success) {
                setLoading(false);
            }
            else {
                console.log(res, "error");
                setLoading(false);
            }
        })
    };
    console.log("tempTitle", tempTitle);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(searchSku(1, limit, filterSKU, tempTitle)).then((res) => {
            if (res.success) {
                setLoading(false);
            }
            else {
                console.log(res, "error");
                setLoading(false);
            }
        })
        // dispatch(
        //     getInventory(
        //         pageLimit,
        //         1,
        //         "active",
        //         startDate,
        //         endDate,
        //         gender,
        //         category,
        //         subCategory,
        //         filterSKU,
        //         invSort,
        //         idType,
        //         searchID?.value
        //     )
        // ).then((res) => {
        //     setSpin(false);
        //     setCurrentPage(0);
        // });
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setDataDetail("")
    };

    const columnDetails = [
        {
            title: "Size",
            key: "option1",
            dataIndex: ["option1"],
            width: 40,
            render: (text, record, index) => {
                return (
                    <>
                        <div className=""> {record?.option1 ? record?.option1 : "-"} </div>
                    </>
                );
            },
        },

        {
            title: "Wash",
            key: "option1",
            dataIndex: ["option1"],
            width: 100,
            render: (text, record, index) => {
                return (
                    <>
                        <div className=""> {record?.option2 ? record?.option2 : "-"} </div>
                    </>
                );
            },
        },

        {
            title: "Inseam",
            key: "option1",
            dataIndex: ["option1"],
            width: 70,
            render: (text, record, index) => {
                return (
                    <>
                        <div className="">{record?.option3 ? record?.option3 : "-"} </div>
                    </>
                );
            },
        },

        {
            title: "Stock",
            dataIndex: "inventory_quantity",
            key: "inventory_quantity",
            width: 60,
        },
    ];

    function DefaultTable() {

        return (<>
            <div className="table-responsive scrollbar-style scrollable-table-data">
                <table className="transactions-box table">
                    <thead className="table_heading">
                        <tr>
                            <th className="">S.#</th>
                            <th className="">SKU</th>
                            <th className="">Image</th>
                            <th className="td-width-300">Title</th>
                            <th className="">Price</th>
                            <th className="">Inventory</th>
                            <th className="">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <div className="antd-loading-ift">
                                <Spin size="large"></Spin>
                            </div>
                        ) : getInventoryProducts?.message?.length === 0 || getInventoryProducts?.length === 0 ? (
                            <tr>
                                <td colspan="12">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </td>
                            </tr>
                        ) : (
                            getInventoryProducts?.message?.map((item, i) => {
                                return (
                                    <tr>
                                        <td>{i + 1 + currentPage * limit}</td>
                                        <td>
                                            {item?._source?.variants[0]?.sku}
                                        </td>
                                        <td>
                                            <div className="listing-image inventory-image">
                                                {/* <img src={`${item?._source?.image?.src ? item?._source?.image?.src : defaultImage}`} /> */}
                                                <Image
                                                    src={`${item?._source?.image?.src ? item?._source?.image?.src : defaultImage}`}
                                                />
                                            </div>
                                        </td>
                                        <td title={item?._source?.title.replace(/<\/?[^>]+(>|$)/g, "")}>

                                            {item?._source?.title
                                                ? item?._source?.title.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 100) + '...'
                                                : ""
                                            }
                                        </td>
                                        <td>
                                            ${item?._source?.variants[0]?.price}
                                        </td>
                                        <td>
                                            <div className="balance-area">
                                                <div className="balance-count">
                                                    {item?._source?.variants?.reduce((acc, it) => {
                                                        return (
                                                            acc = acc + it.inventory_quantity
                                                        )
                                                    }, 0)}
                                                </div>
                                                <div className="balance-area">
                                                    <a
                                                        className="balance-btn default-btn outline small-btn"
                                                        onClick={() => {
                                                            showDetailModal(item?._source);
                                                        }}
                                                    >
                                                        View
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {getStatus(item?._source)}
                                        </td>

                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>
        </>
        );
    }
    return (
        <>
            <div className="sales-page-main">
                <div className="title-area mb-3 pb-0">
                    <h1 className="mb-0 pb-0">Active</h1>
                </div>
                <div className="brand_container_main aff-payment">
                    <Form >
                        <div className="row w-20-desktop-main">
                            <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">SKU</p>
                                <Select
                                    showSearch
                                    size="large"
                                    className="w-100"
                                    key={4}
                                    type="Number"
                                    notFoundContent={skuLoad ? <Spin size="small" /> : <Empty />}
                                    loading={skuLoad}
                                    optionFilterProp="children"
                                    placeholder="Enter SKU"
                                    onChange={(e) => setFilterSKU(e)}
                                    onSearch={(e) => setTempSKU(e)}
                                    filterOption={(input, options) =>
                                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    focus={false}
                                    // onChange={handleFilterSKU}
                                    value={filterSKU}
                                    onBlur={false}
                                >
                                    {searchSKUs && !skuLoad &&
                                        searchSKUs?.map((item, i) => {
                                            return (
                                                <Option value={item}>
                                                    {item}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </div>
                            <div className="col-xl-3 col-md-6 col-12 mb-15">
                                <p className="mb-5rem">Title</p>
                                <Input
                                    className="w-100"
                                    placeholder="Enter Title"
                                    size="large"
                                    type="text"
                                    value={tempTitle}
                                    onChange={(e) => setTempTitle(e?.target?.value)}
                                />
                            </div>

                            <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
                                <Button
                                    onClick={handleSubmit}
                                    className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Search
                                </Button>
                                <Button
                                    onClick={handleReset}
                                    className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Reset
                                </Button>
                            </div>
                        </div>
                    </Form >
                    <hr className="separator-line mt-0" />
                </div >
                <div className="brand_container_main aff-payment">
                    <div className="purchase-data referral-data">
                        {loading ? (
                            <div className="loading-wrap">
                                <span className="spinclass loader-center position-relative">
                                    <Spin size="large" />
                                </span>
                            </div>
                        ) : (
                            <>
                                <DefaultTable />
                                {/* <div className="purchase-data referral-data">
                    {boostedReviews?.payload?.data?.length ? (
                        <ReactPaginate
                        nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                        pageCount={Math.ceil(boostedReviews?.payload?.count / limit)}
                        forcePage={currentPage}
                        previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                        pageClassName="page-item d-none"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item d-none"
                        breakLinkClassName="page-link"
                        containerClassName={"pagination custom-paginate"}
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        />
                    ) : (
                        ""
                    )}
                    </div> */}
                                <Modal
                                    maskClosable={false}
                                    className="modal-generic modal-700 product-detail"
                                    centered
                                    visible={isModalOpen}
                                    onOk={handleOk}
                                    onCancel={handleCancel}
                                    closeIcon={<FontAwesomeIcon icon={faXmark} />}
                                    footer={false}
                                >
                                    <h2 className="modal-hd1">Product Details</h2>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <Carousel>
                                                {dataDetails?.images?.map((item, i) => {
                                                    return (
                                                        <div>
                                                            <img src={`${item?.src ? item.src : defaultImage}`} className="img-fluid" />
                                                        </div>
                                                    )
                                                })}
                                            </Carousel>
                                        </div>
                                        <div className="col-md-7 items-column">
                                            <div class="product-items">
                                                <span className="product-data">Product Title :</span>
                                                <span className="product-desc">
                                                    {dataDetails?.title}
                                                </span>
                                            </div>
                                            <div class="product-items">
                                                <span className="product-data">Product ID :</span>
                                                <span className="product-desc">
                                                    {dataDetails && dataDetails?.variants[0]?.product_id}
                                                </span>
                                            </div>
                                            <div class="product-items">
                                                <span className="product-data">Product SKU :</span>
                                                <span className="product-desc">
                                                    {dataDetails && dataDetails?.variants[0]?.sku ? dataDetails?.variants[0]?.sku : "-"}
                                                </span>
                                            </div>
                                            <div class="product-items">
                                                <span className="product-data">Product Link:</span>
                                                <span className="product-desc">
                                                    <a target="_blank" href={'https://' + dataDetails?.domain + '/products/' + dataDetails?.handle}>{'https://' + dataDetails?.domain + '/...'}</a>
                                                </span>
                                            </div>

                                            <div class="product-items">
                                                <span className="product-data">Product Price :</span>
                                                <span className="product-desc">
                                                    ${dataDetails && dataDetails?.variants[0]?.price}
                                                </span>
                                            </div>
                                            <div class="product-items">
                                                <span className="product-data">Product Description :</span>
                                                <span className="product-desc">
                                                    <TextArea rows={2} value={dataDetails && dataDetails?.body_html
                                                        ? dataDetails?.body_html.replace(/<\/?[^>]+(>|$)/g, "")
                                                        : ""} />
                                                </span>
                                            </div>
                                            <div class="product-items">
                                                <span className="product-data">Product Tags :</span>
                                                <span className="product-desc">
                                                    <Space size={[0, 8]} wrap>
                                                        {dataDetails?.tags?.split(',')?.map((item, i) => {
                                                            return (
                                                                <Tag>{item}</Tag>
                                                            )
                                                        })}
                                                    </Space>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="co mt-3">
                                        <h4>Variants</h4>
                                        <Table
                                            pagination={false}
                                            // columns={skuNewArr?.[viewIndex]}
                                            columns={columnDetails}
                                            size="small"
                                            className="product-listing-tabl inventory-box-main details-table"
                                            //  rowSelection={{ ...rowSelection }}
                                            dataSource={dataDetails?.variants}
                                            // scroll={
                                            //     dataDetails.length > 32
                                            //         ? { y: 450, x: 500 }
                                            //         : false
                                            // }
                                            scroll={
                                                dataDetails?.variants?.length > 10
                                                    ? { y: 400, x: 500 }
                                                    : { x: 500 }
                                            }
                                        />
                                    </div>
                                </Modal>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
