import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";
import Login from "../pages/login/login";
import Menus from "./menus";
import { createBrowserHistory } from "history";
import ForgotPassword from "../pages/forgot-password/forgotPassword";
import ResetPassword from "../pages/reset-password/resetPassword";
import PublicHome from "../pages/public/public-home";
import PublicAbout from "../pages/public/public-about";
import PublicPackage from "../pages/public/public-package";
import PublicPrivacyPolicy from "../pages/public/public-privacy-policy";
import PublicTermsUse from "../pages/public/public-terms-use";
import PublicContactUs from "../pages/public/public-contact-us";
import Register from "../pages/register/register";
import ScrollToTop from "../components/scrollTop/scrollTop";
import { validateUser } from "../redux/actions/login.action";
import { useDispatch, useSelector } from "react-redux";
import { BASEURL } from "../config";
import axios from "axios";
import SkeletonComponent from "../components/SkeletenLoader";
import { getSocialFollowers } from "../redux/actions/socialFollowers.action ";
import Payment from "../pages/public/payment";
import { getProducts } from "../redux/actions/shopify.action";
import moment from "moment";
import { getShowRecordedEvents, showErrMessage } from "../redux/actions/event.action";
import { getInfluencerReview, showErrMessageReviews } from "../redux/actions/influencerReview";
import { getInstaReels } from "../redux/actions/instagramReels.action";
import { store } from "../redux/store";
import { GET_INSTA_DATA, RESET_INSTA_STATE } from "../redux/types/types";

const history = createBrowserHistory();
const token = JSON.parse(localStorage.getItem("token"));

const Router = () => {
  let query = new URL(window.location.href);
  const [nextParams, setNextParams] = useState(null);
  let searchParams = new URLSearchParams(query.search);
  const { validate, uploadDetail, uploadDetailReview, instaReelsData, hitInstaAPI } = useSelector((state) => { return state })
  const [load, setLoad] = useState(true)
  const dispatch = useDispatch();
  useEffect(() => {

    if (window.location.href.includes("payment") && searchParams.get("status") === "success") {
      setLoad(false)
    } else if (token) {
      dispatch(validateUser(validate?.payload?.message?.token)).then((res) => {
        if (res.success) {
          setLoad(false)
        } else {
          setLoad(false)
        }
      })
    } else {
      setLoad(false)
    }
    
  }, [])
  const uploadLocal = JSON.parse(localStorage.getItem('uploads'))
  const uploadReviews = JSON.parse(localStorage.getItem('reviews'))

  useEffect(() => {
    if (uploadDetail?.length > 0 || uploadDetailReview?.length > 0) {
      window.addEventListener('beforeunload', alertUser)
    } else {
      // localStorage.removeItem('uploads')
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [uploadDetail, uploadDetailReview])

  const alertUser = useCallback((e) => {
    e.preventDefault()
    e.returnValue = true;
  }, [])

  useEffect(() => {
    if (uploadLocal) {
      if (uploadLocal?.length > 0) {
        let body = uploadLocal?.map(m => ({ show_s3_id: m.key, error_reason: "Upload Failed due to page refresh" }))
        dispatch(showErrMessage(body, validate?.payload?.message?.token)).then((res) => {
          if (res.success) {
            localStorage.removeItem('uploads')
            getShowRecordedEvents(
              8,
              1,
              '',
              'date',
              moment().startOf('month').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
              ''
            )
          }
        })
      }
    }
    if (uploadReviews) {
      if (uploadReviews?.length > 0) {
        let body = uploadReviews?.map(m => ({ review_s3_id: m.key, error_reason: "Upload Failed due to page refresh" }))
        dispatch(showErrMessageReviews(body, validate?.payload?.message?.token)).then((res) => {
          if (res.success) {
            localStorage.removeItem('reviews')
            getInfluencerReview(
              "active",
              1,
              8,
              moment().startOf('month').format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD'),
              '',
              'date'
            )
          }
        })
      }
    }
  }, [])
  const memoizedResult = useMemo(() => {
    // if (validate?.payload?.success && validate?.payload?.message?.package) {
      //   dispatch(getSocialFollowers(validate?.payload?.message?._id))
      // }
      if (validate?.payload?.message?.token) {
        dispatch(getProducts(validate?.payload?.message?.token))
      }
    }, [validate]);
    useEffect(() => {
      if (validate?.payload?.message?.token) {
        
        instaApi()
    }
  }, [validate, nextParams])

    useEffect(() => {
      setNextParams()
  }, [validate])

    useEffect(() => {
      setNextParams()
      store.dispatch({ type: RESET_INSTA_STATE });
  }, [hitInstaAPI])
  const instaApi = () => {
    dispatch(getInstaReels(nextParams ? nextParams : '', '')).then(
      (res) => {
        if (res?.success) {
          if (res?.nextCursor) {
            // console.log("nextParamssssssss", JSON.parse(res?.nextCursor));
          setNextParams(res?.nextCursor);
        }
      }
    })
  }
  axios.defaults.baseURL = BASEURL;
  axios.defaults.headers.common['Authorization'] = `Bearer ${validate?.payload?.message?.token}`;
  axios.interceptors.request.use(request => {
    return request;
  }, error => {
    return Promise.reject(error);
  });


  function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }

  return (
    <>
      <HistoryRouter history={history}>
        {load ? <SkeletonComponent /> : (!validate?.payload?.success ? (
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/about" element={<PublicAbout />} />
              {/* <Route path="/public-package" element={<PublicPackage />} /> */}
              <Route path="/payment" element={<Payment />} />
              <Route path="/privacy-policy" element={<PublicPrivacyPolicy />} />
              <Route path="/terms-use" element={<PublicTermsUse />} />
              <Route path="/contact-us" element={<PublicContactUs />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path="/register" element={<Register />} /> */}
              <Route
                path="/forgot-password"
                exact
                element={<ForgotPassword />}
              />
              <Route
                path="/password-reset/:id"
                exact
                element={<ResetPassword />}
              />
              <Route path="*" element={<Redirect to="/" />} />
            </Routes>
          </ScrollToTop>
        ) : (
          <Menus />
        ))
        }
      </HistoryRouter>
    </>
  );
};
export default Router;
