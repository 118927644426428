import {
  BRAND_FOLLOWERS
} from '../types/types';
import axios from 'axios';

export const brandFollowers = (_id) => (dispatch) => {

  return axios({
    method: 'POST',
    url: `brand/reports/getAllFollowersForBrand`,
    data: {
      _id
    },
  })
    .then((response) => {
      dispatch({
        type: BRAND_FOLLOWERS,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};
