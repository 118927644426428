//ARAJ IP
//  export const BASEURL = "http://172.16.1.85:9090/v1/";
//
/// PARTY IP ADDRESS
//export const BASEURL = 'http://172.16.1.58:9090/v1/'; //party

/// LIVE URL
// export const BASEURL = "https://apiv2.konnect.bio/v1/"; //live url
export const BASEURL = "https://dev2backend.ormelive.com/v1"; //live urlss dev sss
//export const BASEURL = "https://backendlive.ormelive.com/v1/"; ////live url liveeee

// export const BASEURL = 'https://kbdev-v2.herokuapp.com/v1/';
//export const BASEURL = 'http://172.16.1.157:9090/v1/'; //Chota Kashif
// export const BASEURL = 'https://apiv2.kblive.com/v1/'; sads
export const visitorURL = "https://ormelive.com";
export const TEMPURL = "https://kbsly.com/v1/";
