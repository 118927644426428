import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, InputNumber, Modal, notification, Spin } from "antd";
import { Button } from "react-bootstrap";
import { Button as Buttond } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  getAffiliateContractDetail,
  getAffiliateCards,
  makePayment,
  setPaymentDefault,
  deletePaymentMethod,
  editPaymentMethod,
} from "../../redux/actions/kbSetup";
import { getUserInfo } from "../../redux/actions/userInfo.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default () => {
  const { confirm } = Modal;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [methodLoad, setMethodLoad] = useState(false);
  const [data, setData] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);
  const [defaultPaymentID, setDefaultPaymentID] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [methodID, setMethodID] = useState("");

  const {
    affiliateCards,
    affiliatePayment,
    userInfo,
  } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    dispatch(getAffiliateCards()).then(() => {
      setCardLoading(false);
    }).catch((err) => {
      setCardLoading(false);
    });
    dispatch(makePayment()).then((res) => {
    }).catch((err) => {
    });;
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    // setLoading(true);
    dispatch(getAffiliateContractDetail()).then((res) => {
      setLoading(false);
      setData(res?.payload?.message);
    });
  }, []);

  const paymentMethod = () => {
    if (affiliatePayment?.success == true) {
      let data = affiliatePayment?.message;
      window.open(data, "_self");
    } else {
    }
  };

  const setMethodDefault = () => {
    setMethodLoad(true)
    dispatch(setPaymentDefault(defaultPaymentID)).then((res) => {
      if (res?.success) {
        setMethodLoad(false)
        notification.success({
          message: "Selected Payment Method Has Been Set To Default",
          className: "toast-success",
        });
        console.log('res', res);
        setDefaultPaymentID("")
      } else {
        console.log("error", res);
        setMethodLoad(false)
      }
    })
  };


  const deleteMethod = (id) => {
    confirm({
      wrapClassName: "modal-generic cancel-event-modal min-modal-600",
      // title: 'Warning',
      centered: true,
      closable: true,
      icon: <ExclamationCircleOutlined />,
      closeIcon: <FontAwesomeIcon icon={faXmark} />,
      content: "Are you sure you want to delete the payment method ?",
      okText: " Yes, Delete",
      okType: "success",
      cancelText: " No, I don't want to Delete",
      onOk() {
        dispatch(deletePaymentMethod(id)).then((res) => {
          if (res?.success) {
            notification.success({
              message: "Payment Method Has Been Deleted",
              className: "toast-success",
            });
            dispatch(getAffiliateCards()).then(() => {
              setCardLoading(false);
            }).catch((err) => {
              setCardLoading(false);
            });
            console.log('res', res);
          } else {
            console.log("error", res);
          }
        })
      },
      onCancel() {
        console.log("cancel Delete");
      },
    });

  };

  const editMethodDates = () => {
    setLoad(true)
    dispatch(editPaymentMethod(methodID, expMonth, expYear)).then((res) => {
      if (res.success) {
        notification.success({
          message: "Expiry Date Has Been Updated",
          className: "toast-success",
        });
        setShowModal(false)
        setLoad(false)
        setCardLoading(true)
        dispatch(getAffiliateCards()).then(() => {
          setCardLoading(false);
        }).catch((err) => {
          setCardLoading(false);
        });
      } else {
        setLoad(false)
      }
    }).catch((err) => {
      setLoad(false)
      notification.error({
        message: "Invalid Expiry Year",
        className: "toast-error",
      });
    })
  }


  function dataCardDetail() {
    let data = affiliateCards?.message?.data;
    if (data) {
      return (
        <>
          <div className="amount-box">
            {data.map((item, i) => {
              return (
                <>
                  <div className="deposit_card">
                    <input
                      type="radio"
                      name="card"
                      id={item.id}
                      class="infchecked"
                      value={item.id}
                      defaultChecked={i === 0 ? true : false}
                      onChange={() => {
                        setDefaultPaymentID(item?.id)
                      }}
                    />
                    <label for={item.id}>
                      <div className="pull-left">
                        <span className="card-name">{item.card.brand}</span>{" "}
                        ending in {item.card.last4}
                      </div>
                      <div className="text-right">
                        <span className="card-expired">
                          {" "}
                          Expired: {item.card.exp_month}/{item.card.exp_year}
                        </span>
                        {/* <span className="fa fa-pencil edit-icon ml-2"></span>
                          <span className="fa fa-trash ml-2"></span> */}
                      </div>
                    </label>
                    <Buttond
                      className="default-btn red-btn payment-btns ml-3 width-auto d-flex align-items-center justify-content-center"
                      type="primary"
                      size="small"
                      key="cancel"
                      onClick={() => deleteMethod(item?.id)}
                      icon={
                        <FontAwesomeIcon
                          size="sm"
                          className="event-icon"
                          icon={faTrash}
                        />
                      }
                    ></Buttond>
                    <Buttond
                      className="default-btn payment-btns width-auto d-flex align-items-center justify-content-center"
                      type="primary"
                      size="small"
                      key="cancel"
                      onClick={() => { setShowModal(true); setMethodID(item?.id); setExpMonth(item?.card?.exp_month); setExpYear(item?.card?.exp_year); }}
                      // disabled
                      icon={
                        <FontAwesomeIcon
                          size="sm"
                          className="event-icon"
                          icon={faPen}
                        />
                      }
                    ></Buttond>
                  </div>
                </>
              );
            })}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="amount-box pt-0">
              <Button
                className="default-btn ml-0 fw-normal"
                onClick={() => paymentMethod()}
              >
                Add Card
              </Button>
            </div>
            <div className="amount-box pt-0">
              <Buttond
                loading={methodLoad}
                className="default-btn ml-0 fw-normal"
                onClick={() => setMethodDefault()}
                disabled={defaultPaymentID ? false : true}
              >
                Save Method
              </Buttond>
            </div>
          </div>
          <Modal
            maskClosable={false}
            className="modal-generic modal-500"
            centered
            open={showModal}
            onCancel={() => setShowModal(false)}
            closeIcon={<FontAwesomeIcon icon={faXmark} />}
            footer={[
              <Buttond
                key="submit"
                type="primary"
                className="ml-3 default-btn"
                loading={load}
                disabled={!expMonth || !expYear}
                onClick={() => {
                  editMethodDates();
                }}
              >
                Update
              </Buttond>,
              <Button type="primary" className="default-btn outline" onClick={() => { setShowModal(false); setExpMonth(""); setExpYear(""); }}>
                Cancel
              </Button>
            ]}
          >
            <h2 className="modal-hd1">Update Expiry Dates</h2>
            <div className="row form-style-new">
              <div className="col-12 mb-15">
                <h6>Expiry Month</h6>
                <InputNumber
                  className="w-100 select-modal"
                  size="large"
                  value={expMonth}
                  parser={(value) => {
                    return value.substring(0, 2)
                  }}
                  min={1}
                  max={31}
                  onChange={(e) => {
                    {
                      setExpMonth(e)
                    }
                  }}
                />
              </div>
              <div className="col-12 mb-15">
                <h6>Expiry Year</h6>
                <InputNumber
                  className="w-100 select-modal"
                  size="large"
                  value={expYear}
                  parser={(value) => {
                    return value.substring(0, 4)
                  }}
                  min={1}
                  // max={100}
                  onChange={(e) => {
                    {
                      setExpYear(e)
                    }
                  }}
                />
              </div>
            </div>
            {/* <div className="my-4 d-flex justify-content-center align-items-center">
              <div>
                <h6>Expiry Month</h6>
                <InputNumber
                  className="w-50 select-modal"
                  size="large"
                  value={expMonth}
                  parser={(value) => {
                    return value.substring(0, 2)
                  }}
                  min={1}
                  max={31}
                  onChange={(e) => {
                    {
                      setExpMonth(e)
                    }
                  }}
                />
              </div>

              <div>
                <h6>Expiry Year</h6>
                <InputNumber
                  className="w-50 select-modal"
                  size="large"
                  value={expYear}
                  parser={(value) => {
                    return value.substring(0, 4)
                  }}
                  min={1}
                  // max={100}
                  onChange={(e) => {
                    {
                      setExpYear(e)
                    }
                  }}
                />
              </div>
            </div> */}
          </Modal>
        </>
      );
    }
  }

  return (
    <>
      <div class="title-area">
        <h1 class="page-title">ORME Setup</h1>
      </div>
      <div className="container p-0 ml-0">
        <div className="row">
          <div className="col-lg-6 col-xl-6">
            <div className="stream-box-white">
              {!loading ? (
                <>
                  <div class="data-listing-box">
                    <h4 class="count-title">Brand Name</h4>
                    <h3 class="count">{userInfo?.message?.data?.name}</h3>
                  </div>
                  <div class="data-listing-box">
                    <h4 class="count-title">ORME Fee</h4>
                    <h3 class="count">{data?.fee ? data?.fee : "0"}%</h3>
                  </div>
                  <div class="data-listing-box">
                    <h4 class="count-title">Billing Cycle</h4>
                    <h3 class="count">{data?.billing_cycle}</h3>
                  </div>
                  <div class="data-listing-box mb-20">
                    <h4 class="count-title">Amount</h4>
                    <h3 class="count">${data?.amount}</h3>
                  </div>
                  <h5 className="mb-15 mt-10">Billing Period</h5>
                  <div class="data-listing-box">
                    <h4 class="count-title">Date From</h4>
                    <h3 class="count">  {moment(data?.from_date)
                      .utc()
                      .format('MM-DD-YYYY')}</h3>
                  </div>
                  <div class="data-listing-box mb-20">
                    <h4 class="count-title">Date to</h4>
                    <h3 class="count">  {moment(data?.to_date)
                      .utc()
                      .format('MM-DD-YYYY')}</h3>

                  </div>

                  <h5 className="mb-15 mt-10"> Payment Method</h5>
                  <div className="amount-box">
                    {/* <div className="deposit_card"> */}

                    {cardLoading ? (
                      <span className="spinclass loader-center position-relative">
                        <Spin size="small" />
                      </span>
                    ) : (
                      <>
                        {affiliateCards?.is_payment_method ? (
                          <>{dataCardDetail()}</>
                        ) : (
                          <>
                            <div className="amount-box pt-0">
                              <Button
                                className="default-btn ml-0 fw-normal"
                                onClick={() => paymentMethod()}
                              >
                                Add Card
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {/* </div> */}
                  </div>
                  {/* 
                  <div>
                    <button
                      className="default-btn ml-0 fw-normal"
                  
                    >
                      Add New Card
                    </button>
                  </div> */}
                  {/* 
                <Row>
                  <Col xs={4}>
                    <span>Brand Name :</span>
                  </Col>
                  <Col xs={8}>{userInfo?.name}</Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={4}>
                    <span>ORME Fee :</span>
                  </Col>
                  <Col xs={8}>{data?.fee ? data?.fee : '0'}%</Col>
                </Row> */}
                </>
              ) : (
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
