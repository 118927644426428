import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import placeholder from "../../images/placeholder.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import LoadingSpin from "../../reUsable/loadingSpin";

import { useDispatch, useSelector } from "react-redux";
import "../../css/category.scss";
import { Select, Space, notification, Spin, Button, Modal } from "antd";
import {
  getGeneralCategories,
  getCategories,
  getSubCategories,
  saveCategories,
  getSavedGeneralCategories,
  getSavedCategories,
  getSavedSubCategories,
} from "../../redux/actions/newCategory.action";
const { Option } = Select;
const { confirm } = Modal;

function CategorySetup() {
  const { newGeneralCategories, newCategories, newSubCategories } = useSelector(
    (state) => {
      return state;
    }
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(true);
  const [catLoad, setCatLoad] = useState(true);
  const [subCatLoad, setSubCatLoad] = useState(true);
  const [gender, setGender] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [subCats, setSubCats] = useState([]);
  const [submitLoad, setSubmitLoad] = useState(false);

  useEffect(() => {
    //general cats
    dispatch(getGeneralCategories())
      .then((res) => {
        if (res.success) {
          // setLoading(false)
        } else {
          // setLoading(false)
        }
      })
      .catch((err) => {
        // setLoading(false)
      });

    // get user's saved cats
    dispatch(getSavedGeneralCategories()).then((res) => {
      if (res.success) {
        if (res.message.length > 0) {
          let genderIDS = res?.message?.map((ress) => {
            return ress?._id;
          });
          setGender(genderIDS);
          dispatch(getCategories(genderIDS))
            .then((res) => {
              if (res.success) {
                setCatLoad(false);
              } else {
                setCatLoad(false);
              }
            })
            .catch((err) => {
              setCatLoad(false);
            });

          // saved cats
          dispatch(getSavedCategories()).then((res) => {
            if (res.success) {
              if (res.message.length > 0) {
                let catIDS = res?.message?.map((ress) => {
                  return ress?._id;
                });
                setCategory(catIDS);
                dispatch(getSubCategories(genderIDS, catIDS))
                  .then((res) => {
                    if (res.success) {
                      if (res?.message?.length > 0) {
                        // saved sub ccats
                        dispatch(getSavedSubCategories())
                          .then((res) => {
                            if (res.success) {
                              let subCatIDS = res?.message?.map((ress) => {
                                return ress?._id;
                              });
                              setSubCategory(subCatIDS);
                              setLoading(false);
                            } else {
                              setLoading(false);
                            }
                          })
                          .catch((err) => {
                            setLoading(false);
                          });
                      }
                      setSubCatLoad(false);
                    } else {
                      setSubCatLoad(false);
                    }
                  })
                  .catch((err) => {
                    setSubCatLoad(false);
                  });
              }
            }
          });
        }

        if (res?.message?.length === 0) {
          setLoad(false);
        }
      }
    });
  }, []);

  useEffect(() => {
    popSubCats();
  }, [newSubCategories]);

  const onSelectGender = (value, option) => {
    let genderArr = [...gender];
    genderArr.push(value);
    setGender(genderArr);
    dispatch(getCategories(genderArr));
  };

  const onDeSelectGender = (value, option) => {
    confirm({
      title: "Are you sure you want to remove the Gender?",
      content:
        "Removing it will remove all related Categories and Sub-Categories related to this Gender.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        if (gender.length === 1) {
          setCategory([]);
          setSubCategory([]);
        }
        setGender(gender.filter((gen) => gen !== value));
        dispatch(getCategories(gender.filter((gen) => gen !== value))).then(
          (res) => {
            if (res.success) {
              let newCatArr = [];
              res?.message?.map((gen) => {
                return gen?.categories?.filter((cat) => {
                  category.forEach((cats) => {
                    if (cats === cat?._id) {
                      newCatArr.push(cats);
                    }
                  });
                });
              });
              // setCategory(newCatArr)

              dispatch(
                getSubCategories(
                  gender.filter((gen) => gen !== value),
                  newCatArr
                )
              ).then((res) => {
                if (res.success) {
                  let newSubCatArr = [];
                  res?.message?.map((gen) => {
                    return gen?.categories?.map((cat) => {
                      return cat?.sub_categories?.filter((subCat) => {
                        subCategory.forEach((subCates) => {
                          if (subCates === subCat?._id) {
                            newSubCatArr.push(subCates);
                          }
                        });
                      });
                    });
                  });
                  setSubCategory(newSubCatArr);
                  setCategory(newCatArr);
                } else {
                  setCategory(newCatArr);
                }
              });
            }
          }
        );
      },
      onCancel() {},
    });
  };

  const onSelectCategory = (value, option) => {
    let categoryArr = [...category];
    categoryArr.push(value);
    setCategory(categoryArr);
    dispatch(getSubCategories(gender, categoryArr));
  };

  const onDeSelectCategory = (value, option) => {
    confirm({
      title: "Are you sure you want to remove the Category?",
      content:
        "Removing it will remove all related Sub-Categories related to this Category.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        if (category.length === 1) {
          setSubCategory([]);
        }
        setCategory(category.filter((cat) => cat !== value));
        dispatch(
          getSubCategories(
            gender,
            category.filter((cat) => cat !== value)
          )
        ).then((res) => {
          if (res.success) {
            let newSubCatArr = [];
            res?.message?.map((gen) => {
              return gen?.categories?.map((cat) => {
                return cat?.sub_categories?.filter((subCat) => {
                  subCategory.forEach((subCates) => {
                    if (subCates === subCat?._id) {
                      newSubCatArr.push(subCates);
                    }
                  });
                });
              });
            });
            setSubCategory(newSubCatArr);
          }
        });
      },
      onCancel() {},
    });
  };

  const onSelectSubCategory = (value, option) => {
    let subCategoryArr = [...subCategory];
    subCategoryArr.push(value);
    setSubCategory(subCategoryArr);
  };

  const onDeSelectSubCategory = (value, option) => {
    confirm({
      title: "Are you sure you want to remove the Sub-Category?",
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setSubCategory(subCategory.filter((cat) => cat !== value));
      },
      onCancel() {},
    });
  };

  const handleSubmit = () => {
    setSubmitLoad(true);
    dispatch(saveCategories(gender, category, subCategory)).then((res) => {
      if (res.success) {
        setSubmitLoad(false);
        notification.success({
          message: "Categories Have Been Saved Successfully",
          className: "toast-success",
        });
      } else {
        setSubmitLoad(false);
        notification.error({
          message: res?.response?.data?.message,
          className: "toast-error",
        });
      }
    });
  };

  const popSubCats = () => {
    let arr = [];
    newSubCategories?.message?.map((item) => {
      item?.categories?.map((items) => {
        arr.push({
          label: item.name + " > " + items.category_name,
          options: items.sub_categories.map((subCat) => {
            console.log("subCat", subCat);
            return {
              label: subCat?.sub_category_name,
              value:  subCat?._id,
              subLabel: item?.name.substring(0, 1) + "-" + subCat?.sub_category_name,
              key:  subCat?._id,
            };
          }),
        });
      });
    });
    setSubCats(arr);
  };


  console.log("subCats", subCats);
  return (
    <>
      <div className="title-area">
        <h1 className="page-title">Category Setup</h1>
      </div>
      <div className="container p-0 ml-0">
        <>
          <div className="row">
            <div className="col-md-8">
              <div className="stream-box-white">
                {
                  // ((loading || catLoad || subCatLoad) && load) ? <LoadingSpin /> :
                  loading && load ? (
                    <LoadingSpin />
                  ) : (
                    <>
                      <Row>
                        <Col md={12}>
                          <p className="mb-5rem">Select Department</p>
                          <Select
                            mode="multiple"
                            className="select-style dark-field w-100"
                            placeholder="Department"
                            size="large"
                            focus={false}
                            onBlur={false}
                            onDeselect={onDeSelectGender}
                            onSelect={onSelectGender}
                            // onChange={onGenderChange}
                            value={gender}
                          >
                            {newGeneralCategories?.message?.map((option) => {
                              return (
                                <Option value={option?.id}>
                                  {option?.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>

                        {gender?.length > 0 && (
                          <Col md={12} className="mt-3">
                            <p className="mb-5rem">Select Category</p>
                            <Select
                              mode="multiple"
                              placeholder="Category"
                              size="large"
                              className="select-style dark-field w-100"
                              onDeselect={onDeSelectCategory}
                              onSelect={onSelectCategory}
                              optionLabelProp="key"
                              // onChange={onCategoryChange}
                              value={category}
                              options={newCategories?.message?.map((item) => {
                                return {
                                  label: item?.name,
                                  options: item?.categories?.map((items) => {
                                    return {
                                      label: items?.category_name,
                                      value: items?._id,
                                      key:
                                        item?.name.substring(0, 1) +
                                        "-" +
                                        items?.category_name,
                                    };
                                  }),
                                };
                              })}
                            />
                          </Col>
                        )}

                        {category?.length > 0 && gender?.length > 0 && (
                          <Col md={12} className="mt-3">
                            <p className="mb-5rem">Select Sub Category</p>
                            <Select
                              mode="multiple"
                              placeholder="Sub Category"
                              size="large"
                              className="select-style dark-field w-100"
                              // onChange={onSubCategoryChange}
                              onDeselect={onDeSelectSubCategory}
                              onSelect={onSelectSubCategory}
                              optionLabelProp="subLabel"
                              value={subCategory}
                              options={subCats?.map((item) => {
                                return {
                                  label: item?.label,
                                  options: item?.options,
                                };
                              })}
                            />
                          </Col>
                        )}

                        <Col xl={12} className="mt-4">
                          <Button
                            variant="primary"
                            type="submit"
                            className="btn category-btn default-btn ml-0 fw-normal"
                            id="cat-save"
                            loading={submitLoad}
                            onClick={handleSubmit}
                            disabled={!subCategory?.length > 0}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}
export default CategorySetup;
