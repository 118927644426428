import React, { useEffect, useState, useRef, useContext } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as influencerReviewAction from "../../redux/actions/influencerReview";
import * as instaReelsAction from "../../redux/actions/instagramReels.action";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleLeft,
	faAngleRight,
	faXmark,
	faCalendarDays,
	faClock,
	faFilePdf,
	faLink,
	faTrash,
	faThumbsUp,
	faComment,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import NoDataFound from "../../reUsable/NoDataFound";
import { Col, Row } from "react-bootstrap";
import {
	Spin,
	Modal,
	Button,
	Form,
	Input,
	Upload,
	message,
	Select,
	notification,
	Avatar,
	Table,
	DatePicker,
	InputNumber,
	Progress,
} from "antd";
import "video-react/dist/video-react.css";
import AsyncProduct from "../create-event/scheduleEvent/asyncProduct";
import GroupIcon from "@mui/icons-material/Group";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import { getAllCreator } from "../../redux/actions/influencerReview";
import ReactPlayer from "react-player";
import LoadingSpin from "../../reUsable/loadingSpin";
import {
	getSavedGeneralCategories,
	getSavedCategories,
	getSavedSubCategories,
} from "../../redux/actions/newCategory.action";
import AsyncInfluencer from "./asyncInfluencer";
import { Percent } from "../../context/PercentProvider";
import { UPLOAD_DONE_REVIEW } from "../../redux/types/types";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getFeeStructure } from "../../redux/actions/feestructure.action";
import { getShopifyDetail } from "../../redux/actions/shopify.action";
import { getInfluencers } from "../../redux/actions/influencerNew.action";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from "@react-pdf/renderer";

import "jspdf-autotable";
import { hitAPIinsta } from "../../redux/actions/hitInstaApi.action";
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";

function LinkReels({
	instaReelsAction,
	getInfluencerVideo,
	linkInfluencerProduct,
	reviewDeleted,
	getInstaReels,
	linkInstaReel,
	instaReels
}) {
	const dispatch = useDispatch();
	const {
		parentCategory,
		getAllCreatorData,
		promoRequest,
		reactS3BucketData,
		savedGeneralCategories,
		uploadDetailReview,
		getStructureFees, getAllInfluencers, instaReelsData
	} = useSelector((state) => {
		return state;
	});

	const socketUrl = process.env.REACT_APP_SOCKET_URL;
	const percent = useContext(Percent);
	const childRef = useRef();
	const didUnmount = useRef(false);
	const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
		socketUrl,
		{
			shouldReconnect: (closeEvent) => {
				return didUnmount.current === false;
			},
			reconnectAttempts: 2,
			reconnectInterval: 3000,
		}
	);
	useEffect(() => {
		dispatch(getShopifyDetail()).then((res) => {
			if (res?.payload?.connection) {
				setShopify(false)
			} else {
				setShopify(true)
			}

		})
		dispatch(getInfluencers()).then((res) => {
		})
		dispatch(getFeeStructure()).then((res) => { });
		return () => {
			didUnmount.current = true;
		};
	}, []);
	const [loading, setLoading] = useState(true);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [showPlayer, setShowPlayer] = useState(false);
	const [shopify, setShopify] = useState(false);
	const [videoData, setVideoData] = useState("");
	const [load, setLoad] = useState(false);
	const [banner, setBanner] = useState([]);
	const [sku, setSku] = useState([]);
	const [skuError, setSkuError] = useState(false);
	const [parent, setParent] = useState("");
	const [saveLoading, setSaveLoading] = useState(false);
	const [dataDetails, setDataDetail] = useState([]);
	const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
	const [flag, setFlag] = useState(false);
	const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
	const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
	const [referralPercent, setReferralPercent] = useState("0");
	const [creatorFee, setCreator_fee] = useState("0");
	const [influencer_percent, setInfluencer_percent] = useState("0");
	// const fromDate = moment().startOf('year').format('YYYY-MM-DD');
	// const toDate = moment(new Date()).format("YYYY-MM-DD");
	const toDate = moment(new Date()).format("YYYY-MM-DD");
	// const fromDate = moment().year(2010).month(8).date(1).format("YYYY-MM-DD");
	const fromDate = moment().startOf('year').format("YYYY-MM-DD");
	const [startDate, setStartDate] = useState(fromDate);
	const [endDate, setEndDate] = useState(toDate);
	const [influencerId, setInfluencerId] = useState();
	const [influencer, setInfluencer] = useState("");
	const [sortBy, setSortBy] = useState();
	const [status, setStatus] = useState();
	const [sortedReels, setSortedReels] = useState([]);
	const limit = 8;
	const firstRender = useRef(true);

	const [gender, setGender] = useState("");
	const [category, setCategory] = useState("");
	const [subCategory, setSubCategory] = useState("");
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [subCategoryOptions, setSubCategoryOptions] = useState([]);
	const [genLoading, setGenLoading] = useState(true);
	const [user, setUser] = useState("");
	const [email, setEmail] = useState("");
	const [userExist, setUserExist] = useState(false);
	const [msg, setMsg] = useState(false);
	const [dateTime, setDate] = useState("");
	const [influencerError, setInfluencerError] = useState(false);
	const [nextParams, setNextParams] = useState(null);
	let nextCursor = '';
	let previousCursor = '';
	const [form] = Form.useForm();


	const [posts, setPosts] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [postsPerPage, setPostsPerPage] = useState(8);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false)
		}, 2200);

		let dateReels = instaReelsData?.filter((reel) => (moment(reel?.timestamp).isBetween
			(moment(startDate), moment(endDate))))

		setSortedReels(dateReels)
		// setSortedReels(instaReelsData)
	}, [instaReelsData])

	const onLinkReel = async (item) => {
		setSaveLoading(true);


		linkInstaReel(item).then((res) => {
			if (res.type === "LINK_INSTA_REELS_SUCCESS") {
				setLoading(true)
				notification.success({
					message: res.payload?.message,
					className: "toast-success",
				});
				setSortBy()
				setStatus()
				setStartDate(fromDate);
				setCurrentPage(0)
				setEndDate(toDate);
				setTimeout(() => {
					setLoading(false)
				}, 1000);
				dispatch(hitAPIinsta("hit"))
			}
			else {

				notification.error({
					message: res.payload?.message,
					className: "toast-error",
				});
			}
		});
		//form.setFieldsValue({ banner: item?.banner });

		//setShowPlayer(true);
		setVideoData(item);
	};


	const pgNum = [];
	for (let i = 1; i <= Math.ceil(sortedReels.length / postsPerPage); i++) {
		pgNum.push(i)
		// pgNum[i-1] = i;
		console.log("i" + i)
	}




	const indexOfLastPost = (currentPage + 1) * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;

	console.log("indexOfLastPost", indexOfLastPost);
	console.log("indexOfFirstPost", indexOfFirstPost);
	//   console.log(indexOfFirstPost);
	//   console.log(indexOfLastPost);

	const currentPosts = sortedReels.slice(indexOfFirstPost, indexOfLastPost)
	//   console.log(currentPosts);



	console.log("pgNum", pgNum);
	console.log("sortedReels", sortedReels);
	console.log("currentPosts", currentPosts);


	function renderContent() {


		if (!loading) {
			if (currentPosts?.length > 0) {
				return (

					<>

						<Row className="post-analytics-tab-boxes-ift influencer-reviews-main insta-boxes-main">
							{currentPosts?.length > 0 ? (
								currentPosts?.map((item, i) => (
									<Col xs={12} xl={3} md={6}>
										<div
											key={i}
											className={`card any_bx analytic-box campaign-box pb-0`}
										>
											<div className="camp-row row">
												<div className="campaign-header col-12">
													<div className="inf-header">
														{/* <span className="review-box-header mr-2">
                          {item?.influencer?.profile_image_url ? (
                            <Avatar
                              icon={<UserOutlined />}
                              src={item?.influencer?.profile_image_url}
                            />
                          ) : (
                            <Avatar icon={<UserOutlined />} />
                          )}
                        </span> */}
														<div className="inf-username-box">
															{item.caption ?
																<span className="inf-username filename">
																	Caption:{" "}
																	<span className="file-color">
																		{item?.caption}{" "}
																	</span>
																</span>
																: <span className="inf-username filename">
																	Caption:{".... "}
																	<span className="file-color">
																	</span>
																</span>}

															{/* <span className="inf-email">{item?.influencer?.email}</span> */}
															{/* <span className="inf-email">Followers: 10</span> */}
														</div>

														{/* <div className="inf-head-followers">
                          <GroupIcon className="fol-icon" />
                          <h5>
                            {item?.influencer?.followers
                              ? numberFormat(item?.influencer?.followers)
                              : 0}
                          </h5>
                        </div> */}
													</div>
												</div>
												<div className="any-post-img-col col-12">
													<div className="any-post-image media-post-new">
														{/* {item?.media_url ? (
                          <div className="any-image-box">
                            <div className="any-image-box-iner review-video">
                              <div className="review-video">
                                <ReactPlayer
                                  className={`review_react_player ${item?.media_url?.split(".")[
                                    item?.media_url?.split(".")?.length - 1
                                    ] === "m3u8" && "objectFit"
                                    }`}
                                  width={"100%"}
                                  height={"250px"}
                                  url={item?.media_url}
                                  controls
                                  light={item?.banner}
                                  playsinline
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex justify-content-center align-items-center w-100 h-100 media-processing">
                              {reactS3BucketData?.payload?.length === 0 ||
                                reactS3BucketData?.length === 0 ? (
                                <>
                                  <div className="d-flex">
                                    <span className="spinclass spin-color position-relative mr-3">
                                      <Spin className="" />
                                    </span>
                                    <div className="process-hd ff-nunito">
                                      Media Processing
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="process-hd ff-nunito">
                                  Media Processed!
                                </div>
                              )}
                            </div>
                          </>
                        )} */}
														<div className="review-video">
															{item?.media_url ? (
																item?.error_reason === "Uploading" &&
																	!item.is_convert ? (
																	<div className="reviewConverting">
																		<Progress
																			className="convertProgress"
																			strokeLinecap="butt"
																			strokeColor={"#52c41a"}
																			showInfo={false}
																			percent={100}
																			size="default"
																			status="active"
																		/>
																		<span className="mt-2">
																			Media is Converting
																		</span>
																	</div>
																) : item?.error_reason ===
																	"Failed on media conversion" ? (
																	<div
																		className="media-processing process-height"
																		style={{ padding: "2rem" }}
																	>
																		<div
																			className="process-hd ff-nunito"
																			style={{ color: "rgb(205, 24, 24)" }}
																		>
																			File is corrupted
																		</div>
																	</div>
																) : (
																	<ReactPlayer
																		className={`review_react_player ${item?.media_url
																			? item?.media_url?.split(".")[
																			item?.media_url?.split(".")?.length - 1
																			] === "m3u8" && "objectFit"
																			: "objectFit"
																			}`}
																		width={"100%"}
																		height={"250px"}
																		url={item?.media_url}
																		controls
																		//light={item?.banner}
																		playsinline
																	/>
																)
															) : (
																<div
																	className="media-processing process-height"
																	style={{ padding: "2rem" }}
																>
																	{percent[item.review_s3_id] ||
																		percent[item.review_s3_id] === 0 ? (
																		<Progress
																			key={item.review_s3_id}
																			percent={percent[item.review_s3_id]}
																			status="active"
																			type="circle"
																			strokeLinecap="butt"
																			strokeColor={"#52c41a"}
																		/>
																	) : item?.error_reason === "Uploading" ? (
																		<div className="process-hd ff-nunito">
																			Please wait while video in processing
																		</div>
																	) : (
																		<div
																			className="process-hd ff-nunito"
																			style={{ color: "rgb(205, 24, 24)" }}
																		>
																			{item?.error_reason}
																		</div>
																	)}
																</div>
															)}
														</div>
													</div>
												</div>

												<div className="inf-content-area ">
													<div className="  d-flex justify-content-between ">
														<div className="event-date-main top-date  mb-2">
															<div className="event-date">
																<FontAwesomeIcon
																	className="event-icon"
																	icon={faThumbsUp}
																/>

																<div className="event-txt">
																	{item?.like_count ? item?.like_count : "0"}
																</div>
															</div>
															<div className="event-date">
																<FontAwesomeIcon
																	className="event-icon"
																	icon={faComment}
																/>

																<div className="event-txt">
																	{item?.comments_count ? item?.comments_count : "0"}
																</div>
															</div>
														</div>
														<div className="reel-status">
															{item?.status}
														</div>
													</div>

													<div className="event-date-main top-date">
														<div className="event-date">
															<FontAwesomeIcon
																className="event-icon"
																icon={faCalendarDays}
															/>
															<div className="event-txt">
																{moment(item?.timestamp)
																	.utc()
																	.format("MM-DD-YYYY")}
															</div>
														</div>
														<div className="event-date">
															<FontAwesomeIcon
																className="event-icon"
																icon={faClock}
															/>
															<div className="event-txt">
																{moment(item?.timestamp).format("hh:mm A")}
															</div>
														</div>
													</div>

												</div>

												{/* <div>
                          <Select
                            showSearch
                            name="sort"
                            size="large"
                            placeholder="Select Creator"
                            value={influencer}
                            className="w-100 mb-2 select-style dark-field"
                            optionFilterProp="children"
                            onChange={(value) => selectInfluencer(value)}
                            // onSearch={onSearch}
                            filterOption={filterOption}
                            options={getAllInfluencers?.message && [
                              ...getAllInfluencers?.message].map((influencer) => {
                                return ({
                                  label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                                  value: influencer?.user_id
                                }
                                )
                              })
                            }
                          />
                          {influencerError && (
                            <small className="creator-error">Please Select Creator</small>
                          )}
                        </div> */}
											</div>
											<div
												// className="cam-buttons col-12"
												className="review-card-footer"
											>


												{/* {deleteLoading ? (
                      <button className="btn">
                        <span className="spinclass loader-center position-relative">
                          <Spin size="small" />
                        </span>
                      </button>
                    ) : (
                      <button
                        className="default-btn medium-btn padb-3 m-0 w-inherit"
                        onClick={() => {
                          onLinkProduct(item);
                        }}
                      >
                        <i className="fa fa-link mr-2" />
                        Link Product
                      </button>
                    )}{" "} */}
												{item?.media_url && (
													// <button
													//   className="default-btn medium-btn padb-3 m-0 w-inherit"
													//   onClick={() => {
													//     if (item?.influencer?.email) {
													//       setUser(item?.influencer);
													//     } else {
													//       setUser(null);
													//     }
													//     onLinkProduct(item);
													//   }}
													// >
													//   <i className="fa fa-link mr-2" />
													//   Link Product
													// </button>


													<Button
														className="default-btn d-flex align-items-center justify-content-center"
														type="primary"
														size="large"
														disabled={item?.status === "" ?
															false : true}
														onClick={() => {
															onLinkReel(item);
														}}
														icon={
															<FontAwesomeIcon
																size="sm"
																className="event-icon"
																icon={faLink}
															/>
														}
													>
														{item?.status === "" ?
															"Link Reel" : "Linked"}
													</Button>
												)}

											</div>
										</div>
									</Col>

								))
							) : (
								<div className="col-md-12">
									<NoDataFound />
								</div>
							)}
						</Row>

						{/* 
						<div id="pgNums" className="mt-5 flex text-center  justify-center ">
							{
								pgNum.map((num, index) => {
									// console.log(`num ${num} index ${index} currentPage ${currentPage}`);
									return (
										<span key={index} onClick={() => { setCurrentPage(num); }} className={` ${currentPage === num ? "" : ""}`}>
											{num}
										</span>
									)
								})
							}
						</div> */}

					</>
				);
			} else {
				return (
					<div className="connect-facebook-area">
						<Link to="/account/social" className="default-btn h-initial">Connect Facebook</Link>
					</div>

				)
			}
		} else {
			return <LoadingSpin />;
		}
	}

	//   const filterOption = (input, option) =>
	//   (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
	// const selectInfluencer = (value) => {
	//   setInfluencer(value);
	//   setFields({
	//     ...fields,
	//     user_id: value,
	//   });
	// }


	const handlePageClick = (e) => {
		setLoading(true);
		const page = e.selected;
		setCurrentPage(page);
		setTimeout(() => {
			setLoading(false)
		}, 200);
	};

	console.log("currentPage", currentPage);











	const dateRangePickerChanger = (value, dataString) => {
		const startDate = dataString[0];
		const endDate = dataString[1];
		setStartDate(startDate);
		setEndDate(endDate);
	};

	const showModalDetail = (s) => {
		setDataDetail(s.variants);
		setIsModalOpenDetail(true);
	};

	const handleCancelDetail = () => {
		setIsModalOpenDetail(false);
	};

	const handleOkDetail = () => {
		setIsModalOpenDetail(false);
	};

	const columnDetails = [
		{
			title: "Size",
			key: "option1",
			dataIndex: ["option1"],
			width: 40,
			render: (text, record, index) => {
				return (
					<>
						<div className=""> {record?.option1 ? record?.option1 : "-"} </div>
					</>
				);
			},
		},

		{
			title: "Wash",
			key: "option1",
			dataIndex: ["option1"],
			width: 100,
			render: (text, record, index) => {
				return (
					<>
						<div className=""> {record?.option2 ? record?.option2 : "-"} </div>
					</>
				);
			},
		},

		{
			title: "Inseam",
			key: "option1",
			dataIndex: ["option1"],
			width: 70,
			render: (text, record, index) => {
				return (
					<>
						<div className="">{record?.option3 ? record?.option3 : "-"} </div>
					</>
				);
			},
		},

		{
			title: "Stock",
			dataIndex: "inventory_quantity",
			key: "inventory_quantity",
			width: 60,
		},
	];


	const handleSearch = (e) => {
		setLoading(true)
		if (status === "linked") {

			if (sortBy === "likes") {
				// let linkedReels = [...instaReelsData];
				let linkedReels = instaReelsData?.filter((links) => links?.status !== "" && (moment(links?.timestamp).isBetween
					(moment(startDate), moment(endDate)))).sort((a, b) => {
						return (
							b.like_count - a.like_count
						)
					})
				setSortedReels(linkedReels)
				console.log("Likes", linkedReels);
			} else if (sortBy === "comments") {
				let linkedReels = instaReelsData?.filter((links) => links?.status !== "" && (moment(links?.timestamp).isBetween
					(moment(startDate), moment(endDate)))).sort((a, b) => {
						return (
							b.comments_count - a.comments_count
						)
					})
				setSortedReels(linkedReels)

				console.log("Comments", linkedReels);
			} else {
				let linkedReels = instaReelsData?.filter((links) => links?.status !== "" && (moment(links?.timestamp).isBetween
					(moment(startDate), moment(endDate))))
				setSortedReels(linkedReels)

				console.log("linkedReels", sortedReels);
			}

		}
		else if (status === "unlinked") {

			if (sortBy === "likes") {
				let unLinkedReels = instaReelsData?.filter((links) => links?.status === "" && (moment(links?.timestamp).isBetween
					(moment(startDate), moment(endDate)))).sort((a, b) => {
						return (
							b.like_count - a.like_count
						)
					})

				setSortedReels(unLinkedReels)
				console.log("unLinkedReels", unLinkedReels);

			} else if (sortBy === "comments") {
				let unLinkedReels = instaReelsData?.filter((links) => links?.status === "" && (moment(links?.timestamp).isBetween
					(moment(startDate), moment(endDate)))).sort((a, b) => {
						return (
							b.comments_count - a.comments_count
						)
					})

				setSortedReels(unLinkedReels)

				console.log("unLinkedReels", unLinkedReels);
			} else {
				let unLinkedReels = instaReelsData?.filter((links) => links?.status === "" && (moment(links?.timestamp).isBetween
					(moment(startDate), moment(endDate))))


				setSortedReels(unLinkedReels)

				console.log("unLinkedReels", sortedReels);
			}
		}
		else if (sortBy === "likes") {
			let likedReels = [...instaReelsData];
			likedReels?.sort((a, b) => {
				return (
					b.like_count - a.like_count
				)
			})

			let DateLikes = likedReels.filter((reel) => {
				console.log(moment(reel?.timestamp));

				if (moment(reel?.timestamp).isBetween
					(moment(startDate), moment(endDate))) {
					return reel
				}
			})

			setSortedReels(DateLikes)

			console.log("DateLikes", DateLikes);
		}
		else if (sortBy === "comments") {
			let commentReels = [...instaReelsData];
			commentReels?.sort((a, b) => {
				return (
					b.comments_count - a.comments_count
				)
			})


			let DateComments = commentReels.filter((reel) => {
				console.log(moment(reel?.timestamp));

				if (moment(reel?.timestamp).isBetween
					(moment(startDate), moment(endDate))) {
					return reel
				}
			})
			setSortedReels(DateComments)

			console.log("DateComments", DateComments);
		}
		else {

			let dateReels = instaReelsData?.filter((reel) => (moment(reel?.timestamp).isBetween
				(moment(startDate), moment(endDate))))

			setSortedReels(dateReels)

		}
		setTimeout(() => {
			setLoading(false)
		}, 200);
	};


	const handleReset = () => {
		setLoading(true)
		setTimeout(() => {
			setLoading(false)
		}, 200);
		setSortBy()
		setStatus()
		setStartDate(fromDate);
		setEndDate(toDate);
		setCurrentPage(0)
		setSortedReels(instaReelsData)
	};


	const handleStatus = (value) => {
		setStatus(value);
	};

	const handleSort = (value) => {
		setSortBy(value);
	};


	return (
		<div className="container-fluid p-0 ">
			<div className="row w-20-desktop-main w-25-desktop-main align-items-center">
				<div className="col-xl-3 col-md-6 col-12 mb-5">
					<p className="mb-5rem">Select Start Date / End Date</p>
					<RangePicker
						size="large"
						className="w-100"
						key={4}
						value={
							startDate && endDate ? [moment(startDate), moment(endDate)] : []
						}
						allowClear={false}
						ranges={{

							"Last Year": [
								moment().subtract(1, "year").startOf("year"),
								moment().subtract(1, "year").endOf("year"),
							],
							"This Year": [moment().startOf("year"), moment().endOf("year")],
							"Last Month": [
								moment().subtract(1, "month").startOf("month"),
								moment().subtract(1, "month").endOf("month"),
							],
							"This Month": [
								moment().startOf("month"),
								moment().endOf("month"),
							],
						}}
						format={dateFormat}
						onChange={dateRangePickerChanger}
					/>
				</div>
				<div className="col-xl-3 col-md-6 col-12 mb-5">
					<p className="mb-5rem">Status</p>
					<Select
						className="w-100 select-style dark-field"
						placeholder="Select Status"
						size="large"
						// focus={false}
						// onBlur={false}
						onChange={handleStatus}
						value={status}
					// defaultValue="date"
					>
						{/* <Option value="date">Date</Option> */}
						<Option value="linked">Linked</Option>
						<Option value="unlinked">UnLinked</Option>
					</Select>
				</div>
				<div className="col-xl-3 col-md-6 col-12 mb-5">
					<p className="mb-5rem">Sort By</p>
					<Select
						className="w-100 select-style dark-field"
						placeholder="Select Sort By"
						size="large"
						// focus={false}
						// onBlur={false}
						onChange={handleSort}
						value={sortBy}
					// defaultValue="date"
					>
						<Option value="likes">Likes</Option>
						<Option value="comments">Comments</Option>
					</Select>
				</div>
				<div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
					<Button
						onClick={handleSearch}
						className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
						type="primary"
						htmlType="submit"
						loading={loading}
					>
						Search
					</Button>
					<Button
						onClick={handleReset}
						className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
						type="primary"
						htmlType="submit"
						loading={loading}
					>
						Reset
					</Button>
				</div>
			</div>
			{/* <div className="row w-20-desktop-main w-25-desktop-main">
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Select Start Date / End Date</p>
          <RangePicker
            size="large"
            className="w-100"
            key={4}
            value={
              startDate && endDate ? [moment(startDate), moment(endDate)] : []
            }
            allowClear={false}
            ranges={{
             
              "Last Year": [
                moment().subtract(1, "year").startOf("year"),
                moment().subtract(1, "year").endOf("year"),
              ],
              "This Year": [moment().startOf("year"), moment().endOf("year")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
            }}
            format={dateFormat}
            onChange={dateRangePickerChanger}
          />
        </div>
        <div className="col-xl-3 col-md-6 col-12 mb-15">
          <p className="mb-5rem">Select Creator</p>
         
          <Select
            showSearch
            name="sort"
            size="large"
            placeholder="Select Creator"
            className="w-100 select-style dark-field"
            optionFilterProp="children"
value={influencerId}
            onChange={(value) => selectInfluencer(value)}
            // onSearch={onSearch}
            filterOption={filterOption}
            options={getAllInfluencers?.message && [
              ...getAllInfluencers?.message].map((influencer) => {
                return ({
                  label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                  value: influencer?.user_id
                }
                )
              })
            }
          />
        </div>
        
        <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
          <Button
            onClick={handleSearch}
            className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Search
          </Button>

          <Button
            onClick={handleReset}
            className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Reset
          </Button>
        </div>
      </div> */}
			{/* <hr className="separator-line mt-0" /> */}
			{renderContent()}

			{currentPosts?.length > 0 && !loading && (
				<ReactPaginate
					previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
					nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
					pageClassName="page-item "
					pageLinkClassName="page-link custom-paginate-link btn btn-primary"
					previousClassName="page-item"
					previousLinkClassName="page-link custom-paginate-prev btn btn-primary"
					nextClassName="page-item"
					nextLinkClassName="page-link custom-paginate-next btn btn-primary"
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName="page-link"
					forcePage={currentPage}
					pageCount={pgNum?.length}
					marginPagesDisplayed={2}
					pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
					onPageChange={handlePageClick}
					containerClassName={
						"pagination justify-content-center mt-2 custom-paginate review-paginate"
					}
					activeClassName={"active"}
				/>

			)}
			{/* {showPlayer && (
        <Modal
          className="modal-generic modal-800"
          centered
          visible={showPlayer}
          // onOk={handleOk}
          onCancel={handleCancel}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
          maskClosable={false}
          keyboard={false}
        >
          <h2 className="modal-hd1">Link Product</h2>
          {!savedGeneralCategories?.message?.length ? (
            <div class="no-cat-review">
              <div>
                <h4 className="mb-4">Setup Category To Link Product</h4>
                <div className="buttons-area">
                  <Link
                    to="/account/categories"
                    className="default-btn medium-btn fltr-hpr fw-normal"
                  >
                    Setup Category
                  </Link>
                </div>
              </div>
            </div>
          ) :

            shopify ?
              (
                <div class="no-cat-review">
                  <div>
                    <h4 className="mb-4">Setup Shopify</h4>
                    <div className="buttons-area">
                      <Link
                        to="/account/ecommerce"
                        className="default-btn medium-btn fltr-hpr fw-normal"
                      >
                        Connect Shopify
                      </Link>
                    </div>
                  </div>
                </div>
              )
              :
              (
                <>
                  <Form
                    // layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <div class="row">
                      <div className="col-md-7 modal-review-right">
                        <div className="form-styling">
                          <Form.Item
                            name="User"
                            labelCol={{ span: 24 }}
                            label="Creator"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please select Creator",
                          //   },
                          // ]}
                          >
                            <div className="select-inf-field d-flex flow-row">
                             
                              <Select
                                showSearch
                                name="sort"
                                size="large"
                                placeholder="Select Creator"
                                className="w-100 select-style dark-field"
                                optionFilterProp="children"
                                value={user?._id ? user?._id : user}
                                onChange={(value) => setUser(value)}
                                // onSearch={onSearch}
                                filterOption={filterOption}
                                options={getAllInfluencers?.message && [
                                  ...getAllInfluencers?.message].map((influencer) => {
                                    return ({
                                      label: influencer?.name + " - " + influencer?.pixel_id + " - " + influencer?.email,
                                      value: influencer?.user_id
                                    }
                                    )
                                  })
                                }
                              />
                            </div>
                            {msg && (
                              <div
                                role="alert"
                                className="ant-form-item-explain-error"
                              >
                                Please Select Creator
                              </div>
                            )}
                          </Form.Item>
                        </div>
                        <div className="form-styling">
                          <Form.Item
                            name="title"
                            labelCol={{ span: 24 }}
                            label="Video Title"
                            rules={[
                              {
                                required: true,
                                message: "Please enter video title",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Video Title"
                              // value={"test.myshopify.com"}
                              // value="dl1961denim"
                              size="large"
                            // onChange={onChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-styling">
                        
                          <Form.Item
                            labelCol={{ span: 24 }}
                            // initialValue={form.getFieldValue().gen_category_id}
                            label="Department"
                            name="gen_category_id"
                            rules={[
                              {
                                required: true,
                                message: "Please select a department",
                              },
                            ]}
                          >
                            <Select
                              className="select-style dark-field"
                              placeholder="Select Department"
                              size="large"
                              focus={false}
                              onBlur={false}
                              onChange={onGenderChange}
                              value={gender}
                              loading={genLoading}
                              disabled={genLoading}
                            >
                              {savedGeneralCategories?.message?.map((option) => {
                                return (
                                  <Option value={option?.category_id}>
                                    {option?.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>

                          {categoryOptions?.length ? (
                            <Form.Item
                              labelCol={{ span: 24 }}
                              initialValue={"all"}
                              // initialValue={form.getFieldValue().category_id}
                              label="Category"
                              name="category_id"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a category",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select Category"
                                size="large"
                                className="select-style dark-field"
                                onChange={onCategoryChange}
                                value={category ? category : undefined}
                                defaultValue={"all"}
                              >
                                <Option value="all">All</Option>
                                {categoryOptions?.map((option) => {
                                  return (
                                    <Option value={option?.category_id}>
                                      {option?.gen_category?.name} {" > "}
                                      {option?.category_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          ) : null}

                          {subCategoryOptions?.length ? (
                            <Form.Item
                              labelCol={{ span: 24 }}
                              initialValue={"all"}
                              // initialValue={form.getFieldValue().sub_category_id}
                              label="Sub Category"
                              name="sub_category_id"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a sub category",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Sub Category"
                                size="large"
                                className="select-style dark-field"
                                onChange={onSubCategoryChange}
                                value={subCategory ? subCategory : undefined}
                                defaultValue={"all"}
                              >
                                <Option value="all">All</Option>
                                {subCategoryOptions?.map((option) => {
                                  return (
                                    <Option value={option?.sub_category_id}>
                                      {option?.gen_category?.name} {" > "}
                                      {option?.category?.category_name} {" > "}
                                      {option?.sub_category_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          ) : null}
                        </div>
                        <div className="form-styling">
                          <Form.Item
                            name="datetime"
                            // initialValue={
                            //   formState === "add" ? null : form.getFieldValue().datetime
                            // }
                            rules={[
                              {
                                required: true,
                                message: "Please Select Data & Time",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            label="Date and Time"
                          >
                            <DatePicker
                              style={{
                                width: "100%",
                              }}
                              showTime={{ use12Hours: true, format: "HH:mm" }}
                              onChange={(e) => {
                                onDateTimeChange(e);
                                setFlag(true);
                              }}
                              //disabledDate={startDateLimit}
                              format="MM/DD/YYYY h:mm A"
                            />
                          </Form.Item>
                        </div>
                        <div className="form-styling">
                          <Form.Item
                            name="banner"
                            labelCol={{ span: 24 }}
                            label="Upload Banner"
                          >
                            <Form.Item
                              name="banner"
                              labelCol={{ span: 24 }}
                              label="Upload Banner"
                              // valuePropName={formState !== 'edit' && 'fileList'}
                              //initialValue={formState === "add" ? null : form.getFieldValue().banner}
                              //isImageUrl={formState === "add" ? null : "https://cdn.pixabay.com/photo/2017/02/09/21/08/wings-2053515__340.png"}
                              getValueFromEvent={normFile}
                              noStyle
                              multiple={false}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Upload Banner Image",
                                },
                              ]}
                            >
                              <Upload.Dragger
                                customRequest={dummyRequest}
                                className="upload-image-review"
                                {...uploadProps}
                                name="banner"
                                // defaultFileList={
                                //   formState === "edit" &&
                                //   form.getFieldValue()?.banner === null
                                //     ? null
                                //     : [
                                //         {
                                //           url: form.getFieldValue()?.banner,
                                //         },
                                //       ]
                                // }
                                defaultFileList={form.getFieldValue().banner ?
                                  [{ url: form.getFieldValue().banner }]
                                  :
                                  null
                                }
                              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              >
                             
                                <p className="ant-upload-text">
                                  drag files here to upload
                                </p>
                             
                              </Upload.Dragger>
                            </Form.Item>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="modal-video-review-box review-video">
                          <ReactPlayer
                            className={`review_react_player ${videoData?.media_url?.split(".")[
                              videoData?.media_url?.split(".")?.length - 1
                            ] === "m3u8" && "objectFit"
                              }`}
                            width={"100%"}
                            height={"100%"}
                            url={videoData?.media_url}
                            controls
                            playsinline
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mr-0">
                      <div className="col-md-4 col-lg">
                        <label className="pb-0">Promo Code</label>
                        <Select
                          size="small"
                          filterOption={(input, options) =>
                            options.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={
                            productPromoCodePromo
                              ? productPromoCodePromo
                              : "Select PromoCode"
                          }
                          //disabled={!(formState === "add" || formState === "edit")}
                          placeholder="Select PromoCode"
                          //loading={this.state.promoCond}
                          optionFilterProp="children"
                          className="w-100 select-modal mb-20"
                          // onSearch={onSearch}
                          onChange={(options, e) => {
                            changePromoCode(e, options);
                            setFlag(true);
                          }}
                          showSearch
                          allowClear={false}
                          loading={promoRequest?.loading ? true : false}
                          disabled={promoRequest?.loading ? true : false}
                        >
                          {promoRequest?.message?.map((customer, key) => {
                            return (
                              <Option key={customer.promo_percent + " " + key}>
                                {customer.promo}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className="col-md-4 col-lg">
                        <label>Discount</label>
                        <div className="promo_discount form-control mb-20">
                          {productPromoCodeDscs}
                        </div>
                      </div>

                      
                    </div>
                    <div class="row">
                      <div className="col-12">
                        <div className="form-styling">
                          <Form.Item name="productsku">
                            <Form.Item
                              name="productsku"
                              label="Product SKU"
                              labelCol={{ span: 24 }}
                              className="d-flex flex-column sku-field pos-index mb-0"
                            // rules={[
                            //   {
                            //     required: true,
                            //     // message: 'Please Select Product Sku',
                            //   },
                            //   {
                            //     validator: async (_) => {
                            //       if (sku.length === 0) {
                            //         return Promise.reject(
                            //           new Error('Please Select Product SKUcccccccc')
                            //         );
                            //       } else {
                            //         return Promise.resolve();
                            //       }
                            //     },
                            //   },
                            // ]}
                            >
                              <AsyncProduct
                                getSku={getSku}
                                sku={sku}
                              // referralPercent={referralPercent}
                              // influencer_percent={influencer_percent}
                              />
                              {skuError && (
                                <div
                                  role="alert"
                                  className="ant-form-item-explain-error"
                                >
                                  Please Select Product SKU
                                </div>
                              )}
                            </Form.Item>
                          </Form.Item>
                          <div className="row d-flex mb-20 product-listing-table-main">
                            <div className="col">
                              {sku?.length > 0 && (
                                <Table
                                  pagination={false}
                                  columns={columns}
                                  size="small"
                                  className="product-listing-table"
                                  //  rowSelection={{ ...rowSelection }}
                                  dataSource={[...sku]}
                                  scroll={
                                    sku.length > 2
                                      ? { y: 145, x: 1000 }
                                      : { x: 1000 }
                                  }
                                // scroll={
                                //   sku.length > 4
                                //     ? { y: 270, x: window.innerWidth <= 1440 ? 900 : 0 }
                                //     : false
                                // }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-md-end">
                          <Button
                            className="default-btn fw-normal d-flex align-items-center justify-content-center"
                            type="primary"
                            size="large"
                            htmlType="submit"
                            loading={saveLoading}
                          >
                            Save
                          </Button>
                          <Button
                            key="back"
                            className="default-btn  fw-normal outline d-flex align-items-center justify-content-center"
                            onClick={handleCancel}
                          >
                            Exit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              )}
        </Modal>
      )}
      <Modal
        maskClosable={false}
        className="modal-generic modal-600"
        centered
        visible={isModalOpenDetail}
        onOk={handleOkDetail}
        onCancel={handleCancelDetail}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        // footer={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancelDetail}
            className="d-table m-auto"
          >
            Exit
          </Button>,
          // <Button key="submit" type="primary"
          //   className="d-table m-auto"
          //   // onClick={handleOk}
          //   // onClick={handleCancel}
          // >
          //   Submit
          // </Button>,
        ]}
      >
        <h2 className="modal-hd1">Details</h2>
        <div className="co">
          <Table
            pagination={false}
            columns={columnDetails}
            size="small"
            className="product-listing-tabl inventory-box-main details-table"
            //  rowSelection={{ ...rowSelection }}
            dataSource={dataDetails}
            // scroll={{
            //     x: 500,
            //     y: 500,
            // }}
            scroll={dataDetails?.length > 10 ? { y: 400, x: 500 } : { x: 500 }}
          />
        </div>
      </Modal> */}
		</div>
	);
}
function mapStateProps({ instaReels }) {
	return { instaReels };
}
export default connect(mapStateProps, {
	...instaReelsAction,
})(LinkReels);