import { GET_SHIPPING_REQUEST,
    GET_SHIPPING_SUCCESS,
    GET_SHIPPING_ERROR, 
    ADD_SHIPPING_METHOD,
    REMOVE_SHIPPING_METHOD,
    UPDATE_SHIPPING_METHOD,
    GET_ALL_SHIPPING_REQUEST,
    GET_ALL_SHIPPING_SUCCESS,
    GET_ALL_SHIPPING_ERROR
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';

export const getShippingSetup = () => (dispatch) => {
	dispatch({ type: GET_SHIPPING_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}/users/shipping/userShippingMethod`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_SHIPPING_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_SHIPPING_ERROR,
				payload: error.response,
			});
		});
};


export const getAllShippingSetup = () => (dispatch) => {
	dispatch({ type: GET_ALL_SHIPPING_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}/users/shipping/allShippingMethod`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_SHIPPING_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_SHIPPING_ERROR,
				payload: error.response,
			});
		});
};


export const addShippingMethod = (id) => async (dispatch) => {

    let promise = new Promise((resolve, reject) => {
      axios
        .post(`users/shipping/addShippingMethod`, {
            _id: id,

        })
        .then((res) => {
          resolve(res.data);
          dispatch({
            type: ADD_SHIPPING_METHOD,
            payload: res.data,
          });
        })
        .catch(() => {
          dispatch({
            type: ADD_SHIPPING_METHOD,
            payload: [],
          });
          reject("error");
        });
    });
    return promise;
  };

  export const updateShippingMethod = (item) => async (dispatch) => {

    let promise = new Promise((resolve, reject) => {
      axios
        .post(`users/shipping/updateUserShippingMethod`, {
            _id: item.id,
            name: item.name,
            amount: item.amount,
            is_default: true

        })
        .then((res) => {
          resolve(res.data);
          dispatch({
            type: UPDATE_SHIPPING_METHOD,
            payload: res.data,
          });
        })
        .catch(() => {
          dispatch({
            type: UPDATE_SHIPPING_METHOD,
            payload: [],
          });
          reject("error");
        });
    });
    return promise;
  };

  export const removeShippingMethod = (id) => async (dispatch) => {

    let promise = new Promise((resolve, reject) => {
      axios
        .post(`users/shipping/removeShippingMethod`, {
            _id: id,

        })
        .then((res) => {
          resolve(res.data);
          dispatch({
            type: REMOVE_SHIPPING_METHOD,
            payload: res.data,
          });
        })
        .catch((res) => {
          dispatch({
            type: REMOVE_SHIPPING_METHOD,
            payload: res.response.data,
          });
          reject("error");
        });
    });
    return promise;
  };