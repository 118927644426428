import {
  faBagShopping,
  faCalendar,
  faList,
  faMoneyCheckDollar,
  faPercent,
  faPercentage,
  faRecordVinyl,
  faTelevision,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, DatePicker, Select, Spin, Tabs } from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { getParentCategories } from "../../redux/actions/category.action";
import "../../css/dashboard.scss";
import { getMarketFee } from "../../redux/actions/totalEarningSummary.action";
import AsyncCustomer from "./getCustomer/customer";
import {
  getSavedCategories,
  getSavedGeneralCategories,
  getSavedSubCategories,
} from "../../redux/actions/newCategory.action";
import { searchEventID, searchReviewID, searchShowID } from "../../redux/actions/eventID.action";
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "MM-DD-YYYY";
const { TabPane } = Tabs;

let arrHost = [];
let groupKey = "";
export default function MarketFee() {
  const dispatch = useDispatch();
  const limit = 8;
  const [loading, setLoading] = useState(false);
  // const fromDate = moment().startOf('month').format('YYYY-MM-DD');
  // const toDate = moment(new Date()).format('YYYY-MM-DD');
  const min_date = moment().startOf("year").format("YYYY-MM-DD");
  const max_date = moment(new Date()).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(min_date);
  const [endDate, setEndDate] = useState(max_date);
  const [earn, setEarn] = useState("all");
  const [source, setSource] = useState("all");
  const [allCustomer, setAll] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [gender, setGender] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [generalLoad, setGeneralLoad] = useState(false);
  const [catLoad, setCatLoad] = useState(false);
  const [subCatLoad, setSubCatLoad] = useState(false);
  const [searchID, setSearchID] = useState();
  const [searchIDLoad, setSearchIDLoad] = useState("");
  const [searchDataIDs, setSearchDataIDs] = useState([]);
  const [idType, setIdType] = useState("event");

  const {
    marketFeeView,
    savedGeneralCategories,
    savedCategory,
    savedSubCategory,
  } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    setSearchIDLoad(true)
    dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
      setSearchIDLoad(false)
      if (res?.success) {
        console.log("res", res);
        setSearchDataIDs(res?.message)
      }
    })
    setGeneralLoad(true);
    dispatch(getSavedGeneralCategories()).then((res) => {
      if (res.success) {
        setGeneralLoad(false);
      } else {
        setGeneralLoad(false);
      }
    });
    dispatch(
      getMarketFee(startDate, endDate, gender, category, subCategory, earn, searchID,
        idType)
    ).then((res) => {
      setLoading(false);
    });
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleSource = (e) => {
    setSource(e);
  };
  const handleEarnType = (e) => {
    setEarn(e);
  };

  const handleGender = (e) => {
    setGender(e);
    setCategory(null);
    setSubCategory(null);
    setCatLoad(true);
    dispatch(getSavedCategories(e)).then((res) => {
      if (res.success) {
        setCatLoad(false);
      } else {
        setCatLoad(false);
      }
    });
  };
  const handleCategory = (e) => {
    setCategory(e);
    setSubCategory(null);
    setSubCatLoad(true);
    dispatch(getSavedSubCategories(gender, e)).then((res) => {
      if (res.success) {
        setSubCatLoad(false);
      } else {
        setSubCatLoad(false);
      }
    });
  };

  const handleSubCategory = (e) => {
    setSubCategory(e);
  };
  const handleSubmit = (e) => {
    setLoading(true);
    dispatch(
      getMarketFee(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        gender,
        category,
        subCategory,
        earn,
        customer[0]?.value,
        searchID,
        idType
      )
    ).then((res) => {
      setLoading(false);
    });
  };
  const handleIDType = (e) => {
    setIdType(e)
    setSearchDataIDs([])
    setSearchID(null)

    switch (e) {
      case "event":
        return dispatch(searchEventID("", "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {
            console.log("res", res);
            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID("", "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {
            console.log("res", res);
            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID("", "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {
            console.log("res", res);
            setSearchDataIDs(res?.message)
          }
        })
    }
  }

  const handleReviewID = (e) => {
    setSearchIDLoad(true)
    switch (idType) {
      case "event":
        return dispatch(searchEventID(e.replaceAll("-", ''), "event", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {
            console.log("res", res);
            setSearchDataIDs(res?.message)
          }
        })
      case "show":
        return dispatch(searchShowID(e.replaceAll("-", ''), "show", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {
            console.log("res", res);
            setSearchDataIDs(res?.message)
          }
        })
      case "review":
        return dispatch(searchReviewID(e.replaceAll("-", ''), "review", startDate, endDate)).then((res) => {
          setSearchIDLoad(false)
          if (res?.success) {
            console.log("res", res);
            setSearchDataIDs(res?.message)
          }
        })
    }
  }
  const handleReset = () => {
    setIdType("event")
    setSearchID()
    setLoading(true);
    setEarn("all");
    setGender(null);
    setCategory(null);
    setSubCategory(null);
    setCustomerId();
    setCustomer([]);
    setLoading(true);
    const min_date = moment().startOf("year").format("YYYY-MM-DD");
    const max_date = moment(new Date()).format("YYYY-MM-DD");
    setStartDate(min_date);
    setEndDate(max_date);

    dispatch(
      getMarketFee(
        moment().startOf("year").format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD"),
        "",
        "",
        "",
        "all"
      )
    ).then((res) => {
      setLoading(false);
    });
  };

  const loadOptions = async (input, callback) => {
    await smartSearchFilter(input);

    const result = arrHost.filter((item) => {
      return {
        value: `${item.value}`,
        label: `${item.label}`,
      };
    });

    callback(result);
  };

  const smartSearchFilter = async (value) => {
    if (value.length > 0 && value.trim()) {
      await axios
        .post(`brand/reports/getCustomers`, {
          name: value.trim(),
        })
        .then((response) => {
          const loadHost = [];
          const host = response.data.data;
          if (host.length === 0) {
            // notification.error({
            //   message: "No Host Found",
            //   className: "toast-error",
            // });
          }
          setAll(host);
          host.map((item) => {
            return loadHost.push({
              value: item?._id,
              label: item?.first_name + " " + item?.last_name,
            });
          });
          arrHost = loadHost;
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  };

  const handleMultiSelect = (e) => {
    let data = [e];
    if (data.length <= 1) {
      setCustomer(data);
      let lastRemoved = e.slice(0, -1);
      setCustomer(lastRemoved);
    }
  };

  const handleOnPaste = async (e) => {
    const getData = e.clipboardData.getData("text");
    await smartSearchFilter(getData);
  };

  return (
    <>
      {/* <div className="home-intro-box-main">
        No Content
      </div> */}
      <div className="container-fluid ml-0 p-0">
        <div className="row w-20-desktop-main">
          <div className="col-xl-3 col-md-6 col-12 mb-15">
            <p className="mb-5rem">Select Start Date / End Date</p>
            <RangePicker
              size="large"
              className="w-100"
              key={4}
              value={
                startDate && endDate ? [moment(startDate), moment(endDate)] : []
              }
              allowClear={false}
              ranges={{
                // Today: [moment(), moment()],
                // Tomorrow: [
                //   moment().add(1, 'days'),
                //   moment().add(1, 'days'),
                // ],
                // Yesterday: [
                //   moment().subtract(1, 'days'),
                //   moment().subtract(1, 'days'),
                // ],
                "Last Year": [
                  moment().subtract(1, "year").startOf("year"),
                  moment().subtract(1, "year").endOf("year"),
                ],
                "This Year": [moment().startOf("year"), moment().endOf("year")],
                "Last Month": [
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              format={dateFormat}
              onChange={dateRangePickerChanger}
            />
          </div>
          <div className="col-xl-3 col-md-6 col-12 mb-15">
            <p className="mb-5rem">Search ID</p>
            <div className='d-flex align-items-center'>
              <Select
                placeholder="Type"
                size="large"
                className='idTypeSelect'
                style={{
                  width: '35%',
                }}
                focus={false}
                loading={loading}
                onBlur={false}
                value={idType}
                onChange={(e) => handleIDType(e)}
              >
                <Option value="event">Event</Option>
                <Option value="show">Show</Option>
                <Option value="review">Review</Option>
              </Select>
              <Select
                showSearch
                size="large"
                className="w-100"
                key={4}
                disabled={idType ? false : true}
                notFoundContent={searchIDLoad && <Spin size="small" />}
                loading={searchIDLoad}
                optionFilterProp="children"
                placeholder="Enter ID"
                labelInValue
                onChange={(e) => setSearchID(e)}
                optionLabelProp="label"
                onSearch={handleReviewID}
                filterOption={(input, options) =>
                  options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                focus={false}
                value={searchID ? searchID?.label?.[0] + "-" + searchID?.label?.split(idType === "event" ? 'E' : (idType === "review" ? "R" : "S"))[1]?.split('-').join('')?.match(/.{1,3}/g)?.join('-') : null}
                onBlur={false}
              >
                {searchDataIDs && !searchIDLoad &&
                  searchDataIDs?.map((item, i) => {
                    return (
                      <Option key={item?._id ? item?._id : (item?._id ? item?._id : item?._id)} label={item?.event_counter ? item?.event_counter : (item?.review_counter ? item?.review_counter : item?.show_counter)} >
                        {
                          idType === "event" ? item?.event_counter && item?.event_counter?.[0] + "-" + item?.event_counter?.split('E')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-') :
                            (idType === "review" ? item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-') :
                              item?.show_counter?.[0] + "-" + item?.show_counter?.split('S')[1]?.split('-')?.join('')?.match(/.{1,3}/g)?.join('-'))
                        }
                      </Option>
                    );
                  })}
              </Select>
            </div>

          </div>
          <div className="col-xl-3 col-md-6 col-12 mb-15">
            <p className="mb-5rem">Department</p>
            <Select
              size="large"
              className="w-100"
              key={4}
              placeholder="Select Department"
              focus={false}
              onChange={handleGender}
              value={gender}
              onBlur={false}
              loading={generalLoad}
              disabled={!savedGeneralCategories || generalLoad}
            >
              {savedGeneralCategories &&
                savedGeneralCategories?.message?.map((item, i) => {
                  return (
                    <Option value={item?.category_id}>{item?.name}</Option>
                  );
                })}
            </Select>
          </div>
          <div className="col-xl-3 col-md-6 col-12 mb-15">
            <p className="mb-5rem">Category</p>
            <Select
              size="large"
              className="w-100"
              key={4}
              placeholder="Select Category"
              focus={false}
              onChange={handleCategory}
              value={category}
              onBlur={false}
              loading={catLoad}
              disabled={!savedCategory.success || catLoad || !gender}
            >
              <Option value="all">{"All"}</Option>
              {savedCategory &&
                savedCategory?.message?.map((item, i) => {
                  return (
                    <Option value={item?.category_id}>
                      {item?.category_name}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="col-xl-3 col-md-6 col-12 mb-15">
            <p className="mb-5rem">Sub Category</p>
            <Select
              size="large"
              className="w-100"
              key={4}
              placeholder="Select Sub Category"
              focus={false}
              onChange={handleSubCategory}
              value={subCategory}
              onBlur={false}
              loading={subCatLoad}
              disabled={
                !savedSubCategory?.success || subCatLoad || !gender || !category
              }
            >
              <Option value="">{"All"}</Option>
              {savedSubCategory &&
                savedSubCategory?.message?.map((item, i) => {
                  return (
                    <Option value={item?.sub_category_id}>
                      {item?.sub_category_name}
                    </Option>
                  );
                })}
            </Select>
          </div>

          <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-15">
            <Button
              onClick={handleSubmit}
              className="default-btn fltr-hpr  medium-btn m-width-100 ml-0 fw-normal"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Search
            </Button>

            <Button
              onClick={handleReset}
              className="default-btn outline fw-normal fltr-hpr medium-btn  res-btn2 min-w-100"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
      <hr className="separator-line mt-0" />
      <div className="container ml-0 p-0 mt-3">
        <div className="row">
          <div className="col-12 mb-2">
            {/* <div className="title-area pb-10">
              <h1 className="title-hd">Market Fee</h1>
            </div> */}
          </div>

          <div className="col-12 col-xl-4 col-lg-4 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon
                className="info-icon-main"
                // icon={faUserFriends}
                icon={faUsd}
              />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      marketFeeView?.payload?.message?.fee_due?.[0]
                        ?.cashback_amount
                        ? marketFeeView?.payload?.message?.fee_due?.[0]
                          ?.cashback_amount
                        : 0.0
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">CASHBACK DUE</div>
            </div>
          </div>

          <div className="col-12 col-xl-4 col-lg-4 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon
                className="info-icon-main"
                // icon={faUserFriends}
                icon={faPercent}
              />

              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      marketFeeView?.payload?.message?.fee_due?.[0]
                        ?.customer_commission
                        ? marketFeeView?.payload?.message?.fee_due?.[0]
                          ?.customer_commission
                        : 0.0
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">REFERRAL FEE DUE</div>
            </div>
          </div>
          <div className="col-12 col-xl-4 col-lg-4 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon
                className="info-icon-main"
                // icon={faUserFriends}
                icon={faPercent}
              />

              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      marketFeeView?.payload?.message?.fee_due?.[0]
                        ?.creator_commission
                        ? marketFeeView?.payload?.message?.fee_due?.[0]
                          ?.creator_commission
                        : 0.0
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">CREATOR FEE DUE</div>
            </div>
          </div>

          <div className="col-12 col-xl-4 col-lg-4 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon
                className="info-icon-main"
                // icon={faUserFriends}
                icon={faUsd}
              />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      marketFeeView?.payload?.message?.fee_paid?.[0]
                        ?.customer_commission
                        ? marketFeeView?.payload?.message?.fee_paid?.[0]
                          ?.customer_commission
                        : 0.0
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">CASHBACK PAID</div>
            </div>
          </div>

          <div className="col-12 col-xl-4 col-lg-4 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon
                className="info-icon-main"
                // icon={faUserFriends}
                icon={faMoneyCheckDollar}
              />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      marketFeeView?.payload?.message?.pending_balance?.[0]
                        ?.total_commission
                        ? marketFeeView?.payload?.message?.pending_balance?.[0]
                          ?.total_commission
                        : 0.0
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">REFERRAL FEE PAID</div>
            </div>
          </div>
          <div className="col-12 col-xl-4 col-lg-4 col-md-6">
            <div className="dashboard-info-box">
              <FontAwesomeIcon
                className="info-icon-main"
                // icon={faUserFriends}
                icon={faMoneyCheckDollar}
              />
              <div class="value-info-hd">
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <div>
                    {numeral(
                      marketFeeView?.payload?.message?.pending_balance?.[0]
                        ?.total_commission
                        ? marketFeeView?.payload?.message?.pending_balance?.[0]
                          ?.total_commission
                        : 0.0
                    ).format("$0,0.0'")}
                  </div>
                )}
              </div>
              <div class="total-info-hd">CREATOR FEE PAID</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
