import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
//import { toast } from "react-toastify";
import NoDataFound from "../../../reUsable/NoDataFound";
import Box from "./Box/box";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
//import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import moment from "moment";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import * as hashActions from "../../../redux/actions/hashtags";
import axios from "axios";
import { BASEURL } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addToReview } from "../../../redux/actions/addToReview.action";
import numeral from "numeral";
import {
  Modal,
  Input,
  Form,
  InputNumber,
  notification,
  DatePicker,
  Spin,
} from "antd";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import Button from '@mui/material/Button';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupIcon from "@mui/icons-material/Group";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import Carousel from "react-material-ui-carousel";
import CarouselIcon from "../../../images/carouselIcon.svg";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";

import Divider from "@mui/material/Divider";
import * as tagActions from "../../../redux/actions/tags";
import { getUserProfile } from "../../../redux/actions/ugcUserProfile.action";
import LoadingSpin from "../../../reUsable/loadingSpin";

const { RangePicker } = DatePicker;

const dateFormat = "MM-DD-YYYY";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const ExpandMore2 = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  // transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function TopHashTag({
  getMarketPlace,
  marketPlace,
  addCampaignToShop,
  getUserCategories,
  getBrandsCategory,
  type,
  title,
  getBrands,
  brands,
  getHashtag,
  hashtags,
  hashtag,
  clearHashtag,
  monitorProfileUser,
}) {
  const [searchLoading, setSearchLoading] = useState(true);
  const [clearLoading, setClearLoading] = useState(false);
  const [spinner, setLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(false);

  const childRef = useRef();
  const [form] = Form.useForm();
  form.setFieldsValue({
    dm_message:
      "Dear Creator, your content tagging @brand has been used by the brand to sell on ormelive.com. You will earn a fee on products tagged to your content. Please login into ormelive.com to manage your account.",
  });
  const { TextArea } = Input;

  const [category, setCategory] = useState({ value: "all", label: "ALL" });
  const [categoryOptions, setCategoryOptions] = useState([]);
  // const [brand, setBrand] = useState({ value: "all", label: "ALL" });
  const [brand, setBrand] = useState("");

  const [sortBy, setSortBy] = useState({
    value: "date",
    label: "DATE",
  });

  const [postType, setPostType] = useState({
    value: "all",
    label: "ALL",
  });

  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState({ value: "desc", label: "DESC" });
  const [currentPage, setCurrentPage] = useState(0);

  // const fromDate = moment().subtract(2, "year").format("YYYY-MM-DD");
  // const fromDate = moment()
  //   .subtract(2, "year")
  //   .startOf("year")
  //   .format("YYYY-MM-DD");
  // const fromDate = moment().startOf('year').format('YYYY-MM-DD');

  // const toDate = moment(new Date()).format('YYYY-MM-DD');
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const fromDate = moment().startOf("year").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  const [expanded, setExpanded] = useState(false);
  const [videoIcon, setVideoIcon] = useState(false);
  const [isCheckedOpen, setIsCheckedOpen] = useState(false);
  const [infoDesc, setInfoDesc] = useState("");
  const [mediaId, setmediaId] = useState("");
  const [userId, setuserId] = useState("");
  const [infoTitle, setInfoTitle] = useState("");
  const [platform, setPlatform] = useState(false);
  const [influencer, setInfluencer] = useState("");
  const [influencerError, setInfluencerError] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [imageThumb, setImageThumb] = useState("");
  const [showAddInfModal, setShowAddInfModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [userExist, setUserExist] = useState();
  const [profileUsername, setUsername] = useState("");
  const [profileFollowerCount, setFollowers] = useState("");
  const [UserId, setUserId] = useState("");
  const [linker, setLinked] = useState();
  const [instaDate, setDate] = useState("");
  const [loadInd, setLoadInd] = useState("");
  const [playInd, setPlayInd] = useState("");
  const [pauseIndex, setPauseIndex] = useState("");

  const limit = 8;

  //   useEffect(() => {
  //     setLoading(true);
  //     getMarketPlace(
  //       1,
  //       limit,
  //       "all",
  //       "commission",
  //       "desc",
  //       startDate,
  //       endDate,
  //       type,
  //       brand.value,
  //       "users/marketPlace/getCampaigns"
  //     ).then(function () {
  //       setLoading(false);
  //     });
  //     getBrands();
  //     return () => {};
  //   }, []);

  useEffect(() => {
    axios
      .post(`graph/hash/own`, {})

      .then((res) => {
        setSearchLoading(true);
        setBrand(res.data.message.hashtag_id);
        getHashtag(
          {
            hashtag_id: res.data.message.hashtag_id,
            from_date: startDate.toString(),
            to_date: endDate.toString(),
            sort: sortBy.value,
            order_by: orderBy.value,
            media_type: postType.value,
          },
          1
        )
          .then(() => setSearchLoading(false))
          .catch(() => {
            setSearchLoading(false);
          });
      })
      .catch(() => {
        setSearchLoading(false);
      });
    return () => clearHashtag();
  }, []);

  function onSubmitData(e) {
    e.preventDefault();
    if (brand) {
      setSearchLoading(true);
      getHashtag(
        {
          hashtag_id: brand,
          from_date: startDate.toString(),
          to_date: endDate.toString(),
          sort: sortBy.value,
          order_by: orderBy.value,
          media_type: postType.value,
        },
        1
      )
        .then(() => {
          setSearchLoading(false);
        })
        .catch(() => {
          setSearchLoading(false);
        });
    }
  }

  //   const searchMarketPlace = (e) => {
  //     setSearchLoading(true);
  //     setLoading(true);
  //     setCurrentPage(0);
  //     e.preventDefault();
  //     getMarketPlace(
  //       1,
  //       limit,
  //       category.value,
  //       sortBy.value,
  //       orderBy.value,
  //       startDate,
  //       endDate,
  //       type,
  //       brand.value,
  //       "users/marketPlace/getCampaigns"
  //     ).then(
  //       function () {
  //         setLoading(false);
  //         setSearchLoading(false);
  //       },
  //       function (error) {
  //         setLoading(false);
  //         setSearchLoading(false);
  //         toast.error(error?.response?.data?.message);
  //       }
  //     );
  //   };

  const clearMarketPlace = (e) => {
    setClearLoading(true);
    // setBrand({ value: "all", label: "ALL" });
    // setBrand("");
    setSortBy({ value: "date", label: "DATE" });
    setPostType({ value: "all", label: "ALL" });
    setOrderBy({ value: "desc", label: "DESC" });
    setStartDate(
      // moment().subtract(2, 'year').startOf('year').format('YYYY-MM-DD')
      fromDate
    );
    // setEndDate(moment(new Date()).format('YYYY-MM-DD'));
    setEndDate(toDate);
    // clearHashtag();
    getHashtag({
      hashtag_id: brand,
      from_date: fromDate.toString(),
      to_date: toDate.toString(),
      sort: "date",
      order_by: "desc",
      media_type: "all",
    }).then(() => {
      setClearLoading(false);
    });
  };

  //   const handlePageClick = (e) => {
  //     const page = e.selected;
  //     setCurrentPage(page);
  //     getMarketPlace(
  //       page + 1,
  //       limit,
  //       category.value,
  //       sortBy.value,
  //       orderBy.value,
  //       startDate,
  //       endDate,
  //       type,
  //       brand.value,
  //       "users/marketPlace/getCampaigns"
  //     ).then(function () {
  //       setLoading(false);
  //     });
  //   };

  const style = {
    control: (base) => ({
      ...base,
      height: "40px",
      boxShadow: "none",
      borderRadius: "0px",
      border: "1px solid black",
      "&:hover": {
        border: "1px solid black",
      },
    }),
  };

  const sortByOptions = [
    { value: "date", label: "DATE" },
    { value: "followers", label: "MOST INFLUENTIAL" },
    // { value: "likes", label: "LIKES" },
    // { value: "comments", label: "COMMENTS" },
  ];

  const postByOptions = [
    { value: "all", label: "ALL" },
    { value: "IMAGE", label: "IMAGE" },
    { value: "CAROUSEL_ALBUM", label: "CAROUSEL ALBUM" },
    { value: "VIDEO", label: "VIDEO" },
  ];

  const sortOrderOptions = [
    { value: "asc", label: "ASC" },
    { value: "desc", label: "DESC" },
  ];

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  function Pauseplay(e, id) {
    e.preventDefault();
    var pauseVid = document.getElementById(pauseIndex);
    var testvideo = document.getElementById(id);
    if (pauseIndex !== id) {
      pauseVid?.pause();
    }

    if (testvideo.paused) {
      testvideo.play();
      setVideoIcon(true);
    } else {
      testvideo.pause();
      setVideoIcon(false);
    }
  }

  const onChangeTitle = (e) => {
    setInfoTitle(e.target.value);
  };

  const handleCancelInfo = () => {
    setIsCheckedOpen(false);
    setPlatform(false);
  };

  const closeInfluencerFalse = () => {
    setuserId("");
    setmediaId("");
    setUserExist();
    setUsername("");
    setFollowers("");
    setIsCheckedOpen(false);
  };

  const closeInfluencerTrue = () => {
    setIsCheckedOpen(false);
    form.resetFields();
  };

  const onFinishFalse = (values) => {
    setSaveLoading(true);
    dispatch(
      addToReview(
        "hashtag",
        mediaId,
        UserId,
        profileUsername,
        profileFollowerCount
      )
    ).then((res) => {
      if (res?.payload?.success) {
        notification.success({
          message: res?.payload?.message,
          className: "toast-success",
        });
        setIsCheckedOpen(false);
        setuserId("");
        setmediaId("");
        setUserExist();
        setUsername("");
        setFollowers("");
        form.resetFields();
        childRef.current?.clear();
        setSaveLoading(false);

        setSearchLoading(true);
        if (brand) {
          setSearchLoading(true);
          getHashtag(
            {
              hashtag_id: brand,
              from_date: startDate.toString(),
              to_date: endDate.toString(),
              sort: sortBy.value,
              order_by: orderBy.value,
              media_type: postType.value,
            },
            1
          )
            .then(() => {
              setSearchLoading(false);
            })
            .catch(() => {
              setSearchLoading(false);
            });
        }
      } else {
        setSaveLoading(false);
        notification.error({
          message: res?.payload?.data?.message,
          className: "toast-error",
        });
      }
    });
  };
  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function numberFormat(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + "M";
    if (num >= 1000) return intlFormat(num / 1000) + "k";
    return intlFormat(num);
  }

  const onFinishTrue = (values) => {
    setSaveLoading(true);
    setSaveLoading(false);
    setIsCheckedOpen(false);

    // dispatch(getCustomerRegister(values, monitorProfileUser?.message?.username)).then((res) => {
    //   if (res?.payload?.success) {
    //     notification.success({
    //       message: 'Record Added Successfully!',
    //       className: 'toast-success',
    //     });
    //     setIsCheckedOpen(false);
    //     setSaveLoading(false);
    //     setInstaStatus(false);
    //     setinstaData("");
    //     setInstaUsername("");
    //     form.resetFields();
    //     setInstaUploadData("")
    //     childRef.current?.clear()

    //   } else {
    //     setSaveLoading(false);
    //     notification.error({
    //       message: res?.payload?.data?.message,
    //       className: 'toast-error',
    //     });
    //   }
    // });
  };

  const onFinishFailedTrue = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFalse = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onChangeDescription = (e) => {
    setInfoDesc(e.target.value);
  };

  const OpenSubmit = () => {
    setIsCheckedOpen(false);
  };

  const handleOkInfo = (val, name) => {
    setmediaId(val._id);
    setuserId(val.user_id);
    setSaveLoading(true);
    setDate(val.timestamp);
    setLinked(val.linked);
    dispatch(getUserProfile({ username: name })).then((res) => {
      if (res.payload.success === true) {
        setIsCheckedOpen(true);
        setSaveLoading(false);
        setUserExist(res?.payload?.message?.user_exist);
        setUsername(res?.payload?.message?.username);
        setFollowers(res?.payload?.message?.followers_count);
        setUserId(res?.payload?.message?.user_id);
      }
    });
  };

  function renderMedia(item, index) {
    if (item?.media_type === "IMAGE" || item?.media_type === "CAROUSEL_ALBUM") {
      return (
        <>
          {item?.media_type === "CAROUSEL_ALBUM" && (
            <button className="btn-link btn-play btn-carousel">
              <img
                src={CarouselIcon}
                alt="CarouselIcon"
                className="carouselsvg"
              />
            </button>
          )}
          {item?.children === false ? (
            <CardMedia
              component="img"
              height="400"
              sx={{ objectFit: "cover", borderRadius: 2 }}
              image={item.media_url}
              alt="Paella dish"
            />
          ) : item?.children?.data[0]?.media_type === "VIDEO" ? (
            <>
              {/* <button
                onClick={(e) => Pauseplay(e, item?.children?.data[0]?.id)}
                className="btn-link btn-play"
              >
                {videoIcon && (index === playInd) ? (
                  <i class="fa fa-pause" aria-hidden="true"></i>
                ) : (
                  <i class="fa fa-play" aria-hidden="true"></i>
                )}
              </button> */}
              <CardMedia
                component="video"
                sx={{ objectFit: "cover", borderRadius: 2 }}
                autoPlay={false}
                controls
                //loop
                height="400"
                image={item?.children?.data[0].media_url}
                alt="Paella dish"
                id={item?.children?.data[0].id}
              />
            </>
          ) : (
            <CardMedia
              component="img"
              height="400"
              sx={{ objectFit: "cover", borderRadius: 2 }}
              image={
                item.media_type === "CAROUSEL_ALBUM"
                  ? item.children?.data[0].media_url
                  : item.media_url
              }
              alt="Paella dish"
            />
          )}
        </>
      );
    }
    if (item.media_type === "VIDEO" || item.media_type === "CAROUSEL_ALBUM") {
      return (
        <>
          {/* <button
            onClick={(e) => { Pauseplay(e, item._id); setPauseIndex(item._id) }}
            className="btn-link btn-play"
          >
            {videoIcon && (index === playInd) ? (
              <i class="fa fa-pause" aria-hidden="true"></i>
            ) : (
              <i class="fa fa-play" aria-hidden="true"></i>
            )}
          </button> */}
          <CardMedia
            component="video"
            sx={{ objectFit: "cover", borderRadius: 2 }}
            autoPlay={false}
            controls
            //loop
            height="400"
            image={item.media_url}
            alt="Paella dish"
            id={item._id}
          />
        </>
      );
    }
    return null;
  }

  return (
    <>
      <div className="container-flui">
        <div className="title-area">
          <h1 className="page-title">HashTags</h1>
        </div>
        <Row className="post-analytics-tab mb-">
          <Col xs={12} xl={12} md={12}>
            <form onSubmit={onSubmitData}>
              <Row className="w-20-desktop-main">
                <Col className="col-xl-3" xs={12} md={6}>
                  {/* <Form.Item
                    name="datetime"
                    initialValue={'date'}
                    labelCol={{ span: 24 }}
                    label="Select Start Date / End Date"
                  > */}
                  <p>Select Start Date / End Date</p>
                  <RangePicker
                    size="large"
                    className="w-100"
                    key={4}
                    value={
                      startDate && endDate
                        ? [moment(startDate), moment(endDate)]
                        : []
                    }
                    allowClear={false}
                    ranges={{
                      // Today: [moment(), moment()],
                      // Tomorrow: [
                      //   moment().add(1, 'days'),
                      //   moment().add(1, 'days'),
                      // ],
                      // Yesterday: [
                      //   moment().subtract(1, 'days'),
                      //   moment().subtract(1, 'days'),
                      // ],
                      "Last Year": [
                        moment().subtract(1, "year").startOf("year"),
                        moment().subtract(1, "year").endOf("year"),
                      ],
                      "This Year": [
                        moment().startOf("year"),
                        moment().endOf("year"),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    format={dateFormat}
                    onChange={dateRangePickerChanger}
                  />
                  {/* </Form.Item> */}
                </Col>
                {/* <Col xs={12} xl md={6}>
                  <p>Hashtags</p>
                  <Select
                    value={brand}
                    name="sort"
                    className="selectCustomization"
                    options={[
                      // { hashtag_id: "all", hashtag: "ALL" },
                      ...hashtags.message,
                    ].map((item) => {
                      return {
                        value: item.hashtag_id,
                        label: item.hashtag,
                      };
                    })}
                    onChange={(e) => {
                      setBrand(e);
                    }}
                    placeholder="Select Hashtags"
                    styles={style}
                    isSearchable={false}
                  />
                </Col> */}
                {/* <Col xs={12} xl={2} md={6}>
                    <p>Select Category</p>
                    <Select
                      value={category}
                      name="sort"
                      className="selectCustomization"
                      options={categoryOptions}
                      onChange={(e) => {
                        setCategory(e);
                      }}
                      placeholder="Select Category"
                      styles={style}
                    />
                  </Col> */}
                <Col className="col-xl-3" xs={12} md={6}>
                  {/* <Form.Item labelCol={{ span: 24 }} label="Sort By" name="sort"> */}
                  <p>Post Type</p>
                  <Select
                    size="large"
                    value={postType}
                    name="post"
                    className="selectCustomization select-style dark-field zindex-100 relative"
                    options={postByOptions}
                    onChange={(e) => {
                      setPostType(e);
                    }}
                    placeholder="Post Type"
                    styles={style}
                    isSearchable={false}
                  />
                  {/* </Form.Item> */}
                </Col>
                <Col className="col-xl-3" xs={12} md={6}>
                  {/* <Form.Item labelCol={{ span: 24 }} label="Sort By" name="sort"> */}
                  <p>Sort By</p>
                  <Select
                    size="large"
                    value={sortBy}
                    name="sort"
                    className="selectCustomization select-style dark-field"
                    options={sortByOptions}
                    onChange={(e) => {
                      setSortBy(e);
                    }}
                    placeholder="Sort By"
                    styles={style}
                    isSearchable={false}
                  />
                  {/* </Form.Item> */}
                </Col>

                {/* <Col xs={12} xl md={6}>
                  <p>Order By</p>
                  <Select
                    value={orderBy}
                    name="order"
                    className="selectCustomization"
                    options={sortOrderOptions}
                    onChange={(e) => {
                      setOrderBy(e);
                    }}
                    placeholder="Order By"
                    styles={style}
                    isSearchable={false}
                  />
                </Col> */}
                {/* <Col xs={12} xl={2} md={6}>
                    <p>Order By</p>
                    <Select
                      value={orderBy}
                      name="sort"
                      className="selectCustomization"
                      options={sortOrderOptions}
                      onChange={(e) => {
                        setOrderBy(e);
                      }}
                      placeholder="Order By"
                      styles={style}
                    />
                  </Col> */}
                <Col className="d-flex align-items-end col-xl-3" xs={12} md={6}>
                  {searchLoading ? (
                    <Button
                      type="submit"
                      variant="primary"
                      className="default-btn fltr-hpr  medium-btn ml-0 d-flex justify-content-center min-w-100"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />{" "}
                      Search
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="primary"
                      className="default-btn fltr-hpr  medium-btn ml-0 min-w-100"
                    >
                      Search
                    </Button>
                  )}
                  {clearLoading ? (
                    <Button
                      type="submit"
                      variant="primary"
                      className="default-btn fltr-hpr  medium-btn d-flex justify-content-center min-w-100"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />{" "}
                      Reset
                    </Button>
                  ) : (
                    <Button
                      onClick={clearMarketPlace}
                      className="default-btn outline fltr-hpr  medium-btn  res-btn2 min-w-100"
                      type="primary"
                      size="large"
                    >
                      Reset
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        <hr className="separator-line" />
        <div className="mt-20">
          {searchLoading || clearLoading ? (
            <div className="ugc-loading">
              <LoadingSpin />
            </div>
          ) : hashtag.message.length > 0 ? (
            <InfiniteScroll
              dataLength={hashtag.message.length}
              next={() => {
                getHashtag(
                  {
                    hashtag_id: brand.value,
                    from_date: startDate.toString(),
                    to_date: endDate.toString(),
                    sort: sortBy.value,
                    order_by: orderBy.value,
                  },
                  hashtag.pagination.next?.page,
                  true
                );
              }}
              // hasMore={hashtag.pagination.next ? true : false}
              hasMore={
                hashtag.pagination.next
                  ? hashtag.pagination.next?.page >= 1
                    ? false
                    : true
                  : false
              }
              loader={
                <div
                  style={{
                    height: 100,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span className="spinclass loader-center position-relative">
                    <Spin size="large" />
                  </span>
                </div>
              }
              // pullDownToRefreshThreshold={50}
            >
              <div>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{
                    350: 1,
                    700: 2,
                    1100: 3,
                    1500: 4,
                  }}
                >
                  <Masonry gutter="15px">
                    {hashtag.message.map((item, index) => (
                      <Card elevation={1}>
                        <div className="ugc-card-header">
                          <div className="ugc-head-title">
                            <Typography
                              variant="body"
                              color="#000080"
                              className="ff-nunito"
                            >
                              <a
                                style={{
                                  color: "#000080",
                                  fontSize: 14,
                                  fontWeight: "bold",
                                }}
                                target="_blank"
                                href={`https://www.instagram.com/${item.username}`}
                                maxLength={5}
                              >
                                @
                                {item.username.length > 20
                                  ? item.username.slice(0, 20) + "..."
                                  : item.username}
                              </a>
                            </Typography>
                          </div>
                          <div className="ugc-head-add-button">
                            {item.media_type === "VIDEO" ? (
                              <Typography
                                variant="h6"
                                className="ff-nunito"
                                style={{
                                  color: "black",
                                  // marginTop: 3,
                                  marginRight: 20,
                                }}
                              >
                                {saveLoading && index === loadInd ? (
                                  <Button className="default-btn medium-btn padb-3 w-initial br-radius">
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="md"
                                      role="status"
                                      aria-hidden="true"
                                      // color='white'
                                      variant="light"
                                      // style={{ backgroundColor: "black" }}
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    className="default-btn medium-btn padb-3 w-initial br-radius"
                                    onClick={() => {
                                      handleOkInfo(item, item.username);
                                      setLoadInd(index);
                                    }}
                                    disabled={saveLoading}
                                  >
                                    {/* <Spin size="medium" className="loader-center" /> */}
                                    <FontAwesomeIcon icon={faPlus} />
                                  </Button>
                                )}
                              </Typography>
                            ) : null}
                          </div>
                          <div className="ugc-head-followers">
                            <GroupIcon sx={{ color: "gray", fontSize: 25 }} />
                            <Typography
                              variant="h6"
                              className="ff-nunito"
                              style={{
                                color: "gray",
                                marginTop: 3,
                                marginLeft: 5,
                              }}
                            >
                              {item.userInfo?.business_discovery
                                ?.followers_count
                                ? numberFormat(
                                    item.userInfo?.business_discovery
                                      ?.followers_count
                                  )
                                : 0}
                            </Typography>
                          </div>
                        </div>

                        <Divider />
                        <div
                          className="media-box-post"
                          style={{ padding: "15px" }}
                        >
                          {item.media_type === "CAROUSEL_ALBUM" ? (
                            <a
                              target="_blank"
                              onClick={() => setPlayInd(index)}
                              href={item.permalink}
                            >
                              {renderMedia(item, index)}
                            </a>
                          ) : (
                            //renderCarousel(item)
                            <a
                              target="_blank"
                              onClick={() => setPlayInd(index)}
                              href={item.permalink}
                            >
                              {renderMedia(item, index)}
                            </a>
                          )}
                        </div>
                        <CardActions disableSpacing>
                          {/* {!expanded ? ( */}
                          <div
                          // style={{
                          //   display: "flex",
                          //   justifyContent: "flex-end",
                          //   // marginLeft: 10,
                          //   alignItems: "center",
                          //   flexGrow: 1,
                          // }}
                          >
                            {/* <div
             style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               marginRight: 10,
             }}
           >
             <ThumbUpIcon sx={{ color: "#b3b3b3", fontSize: 16 }} />
             <Typography
               variant="h6"
               style={{ color: "#b3b3b3", marginTop: 3, marginLeft: 5 }}
             >
               {numeral(item.like_count ? item.like_count : 0).format("0,0")}
             </Typography>
           </div> */}
                            {/* <div
             style={{ width: 2, height: 20, backgroundColor: "lightgrey" }}
           /> */}
                            {/* <div
             style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               marginLeft: 10,
             }}
           >
             {item?.commentInfo ? (
               <>
                 <ExpandMore2
                   expand={expanded2}
                   onClick={handleExpand2Click}
                   aria-expanded={expanded2}
                   aria-label="show more"
                 >
                   <CommentIcon sx={{ color: "#b3b3b3", fontSize: 16 }} />
                   <Typography
                     variant="h6"
                     style={{
                       color: "#b3b3b3",
                       marginTop: 3,
                       marginLeft: 5,
                     }}
                   >
                     {numeral(
                       item.comments_count ? item.comments_count : 0
                     ).format("0,0")}
                   </Typography>
                 </ExpandMore2>
               </>
             ) : (
               <>
                 <CommentIcon sx={{ color: "#b3b3b3", fontSize: 16 }} />
                 <Typography
                   variant="h6"
                   style={{ color: "#b3b3b3", marginTop: 3, marginLeft: 5 }}
                 >
                   {numeral(
                     item.comments_count ? item.comments_count : 0
                   ).format("0,0")}
                 </Typography>
               </>
             )}
           </div> */}

                            <Typography
                              variant="body"
                              sx={{ fontSize: "12px", marginLeft: "15px" }}
                              color="gray"
                              textAlign="center"
                            >
                              {moment(item.timestamp)
                                .utc()
                                .format("YYYY-MM-DD")}
                              {/* {new Date().toDateString()} */}
                            </Typography>
                          </div>
                          {/*  ) : null} */}
                          <div
                            style={{
                              display: "flex",
                              flexGrow: 1,
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <ExpandMore
                              expand={expanded && index === loadInd}
                              onClick={() => {
                                setExpanded(
                                  index === loadInd
                                    ? expanded
                                      ? false
                                      : true
                                    : true
                                );
                                setLoadInd(index);
                              }}
                              aria-expanded={expanded}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </ExpandMore>
                          </div>
                        </CardActions>

                        {index === loadInd ? (
                          <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                              <Typography>
                                {item?.commentInfo
                                  ? item?.commentInfo?.text
                                  : item.caption}
                              </Typography>
                            </CardContent>
                            <CardActions
                              sx={{ justifyContent: "space-between" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: 5,
                                }}
                              ></div>
                            </CardActions>
                          </Collapse>
                        ) : null}
                      </Card>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </InfiniteScroll>
          ) : (
            <NoDataFound />
          )}
        </div>

        <Modal
          className="modal-generic modal-500"
          centered
          visible={isCheckedOpen}
          // onOk={handleOk}
          onCancel={handleCancelInfo}
          closeIcon={<FontAwesomeIcon icon={faXmark} />}
          footer={false}
          maskClosable={false}
          keyboard={false}
        >
          <h2 className="modal-hd1">
            {userExist == true ? "Registered Creator" : " UnRegistered Creator"}
          </h2>

          <Form
            name="addinfluencer"
            onFinish={onFinishFalse}
            onFinishFailed={onFinishFailedFalse}
            autoComplete="off"
            form={form}
          >
            <div class="add-influencer-form">
              <Row className="insta-profile mb-20 ml-0 mr-0">
                <Col className="influencer-modal-data-main">
                  <div className="influencer-pic">
                    <img
                      src={monitorProfileUser?.message?.profile_picture_url}
                      alt="profile"
                    />
                  </div>
                  <div class="Influencer-listing-box">
                    <h4 class="count-title">Name</h4>
                    <h3 class="count"> {monitorProfileUser?.message?.name}</h3>
                  </div>
                  <div class="Influencer-listing-box">
                    <h4 class="count-title">Username</h4>
                    <a
                      title={monitorProfileUser?.message?.username}
                      className="inf-username"
                      target="_blank"
                      href={`https://www.instagram.com/${monitorProfileUser?.message?.username}`}
                    >
                      {monitorProfileUser?.message?.username}
                    </a>
                  </div>
                  <div class="Influencer-listing-box">
                    <h4 class="count-title">Followers</h4>
                    <h3 class="count">
                      {" "}
                      {numberFormat(
                        monitorProfileUser?.message?.followers_count
                      )}
                    </h3>
                  </div>
                  <div class="Influencer-listing-box">
                    <h4 class="count-title">Following</h4>
                    <h3 class="count">
                      {" "}
                      {numberFormat(monitorProfileUser?.message?.follows_count)}
                    </h3>
                  </div>
                  <div class="Influencer-listing-box">
                    <h4 class="count-title">Content Date</h4>
                    <h3 class="count">
                      {" "}
                      {moment(instaDate).utc().format("YYYY-MM-DD")}{" "}
                    </h3>
                  </div>
                  <div class="Influencer-listing-box">
                    <h4 class="count-title">Content Status</h4>
                    <h3 class="count"> {linker == true ? "ADDED" : "NEW"} </h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {/* <Form.Item
                      labelCol={{ span: 24 }}
                      label="Message"
                      className="mb-40"
                      initialValue={
                        form.getFieldValue().dm_message
                      }
                      name="dm_message"
                    >
                      <TextArea
                        showCount
                        maxLength={600}
                        // onChange={onChange}
                        className="dark-field"

                        autoSize={{ minRows: 4, maxRows: 6 }}
                      // onFocus={() => setActiveKey('3')}
                      />
                    </Form.Item> */}
                  {userExist == true ? (
                    <p className="text-center">
                      Dear Creator, your content tagging @brand has been used by
                      the brand to sell on ormelive.com. You will earn a fee on
                      products tagged to your content. Please login into
                      ormelive.com to manage your account.
                    </p>
                  ) : (
                    <p className="text-center">
                      Dear Creator, you have posted content tagging @brand. If
                      you like to allow its usage to earn a fee, please register
                      at ormelive.com. Once registered you will be able to see all
                      request.
                    </p>
                  )}
                </Col>
              </Row>
              <div className="separator-line mb-15"></div>
              <div className="d-flex justify-content-between">
                {linker == true ? null : (
                  <Button
                    className="default-btn d-flex align-items-center justify-content-center min-w-initial-res res-btn1"
                    type="primary"
                    size="large"
                    htmlType="submit"

                    // loading={saveLoading}
                  >
                    Add Review
                  </Button>
                )}
                <Button
                  className="default-btn d-flex align-items-center justify-content-center min-w-initial-res res-btn1"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  disabled={true}
                  // loading={saveLoading}
                >
                  Send DM
                </Button>

                <Button
                  key="back"
                  className="default-btn outline d-flex align-items-center justify-content-center min-w-initial-res res-btn1"
                  onClick={closeInfluencerFalse}
                >
                  Exit
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
}

function mapStateToProps({ hashtags, hashtag, monitorProfileUser }) {
  return { hashtags, hashtag, monitorProfileUser };
}

export default connect(mapStateToProps, hashActions)(TopHashTag);
