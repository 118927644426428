import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, InputNumber, Modal, notification, Spin, Checkbox, Radio } from "antd";
import { Button } from "react-bootstrap";
import { Button as Buttond } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
    getAffiliateContractDetail,
    getAffiliateCards,
    makePayment,
    setPaymentDefault,
    deletePaymentMethod,
    editPaymentMethod,
} from "../../redux/actions/kbSetup";
import { getUserInfo } from "../../redux/actions/userInfo.action";
import { getShippingSetup, addShippingMethod, getAllShippingSetup, removeShippingMethod, updateShippingMethod } from "../../redux/actions/shippingSetup.action"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default () => {
    const dispatch = useDispatch();
    const { confirm } = Modal;


    const [loading, setLoading] = useState(true);
    const [load, setLoad] = useState(false);
    const [methodLoad, setMethodLoad] = useState(false);
    const [data, setData] = useState(true);
    const [cardLoading, setCardLoading] = useState(true);
    const [defaultPaymentID, setDefaultPaymentID] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [expMonth, setExpMonth] = useState("");
    const [expYear, setExpYear] = useState("");
    const [methodID, setMethodID] = useState("");
    const [selectedStandard, setSelectedStandard] = useState(false);
    const [selectedOvernight, setSelectedOvernight] = useState(false);
    const [selectedExpress, setSelectedExpress] = useState(false);

    const {
        affiliateCards,
        affiliatePayment,
        userInfo,
        shippingSetup,
        getAllShipping
    } = useSelector((state) => {
        return state;
    });

    useEffect(() => {
        dispatch(getAffiliateCards()).then(() => {
            setCardLoading(false);
        }).catch((err) => {
            setCardLoading(false);
        });
        dispatch(makePayment()).then((res) => {
        }).catch((err) => {
        });;
        dispatch(getUserInfo());
        dispatch(getShippingSetup());
        dispatch(getAllShippingSetup());
    }, []);

    useEffect(() => {
        // setLoading(true);
        dispatch(getAffiliateContractDetail()).then((res) => {
            setLoading(false);
            setData(res?.payload?.message);
        });
    }, []);


    const paymentMethod = () => {
        if (affiliatePayment?.success == true) {
            let data = affiliatePayment?.message;
            window.open(data, "_self");
        } else {
        }
    };

    const setMethodDefault = () => {
        setMethodLoad(true)
        dispatch(setPaymentDefault(defaultPaymentID)).then((res) => {
            if (res?.success) {
                setMethodLoad(false)
                notification.success({
                    message: "Selected Payment Method Has Been Set To Default",
                    className: "toast-success",
                });
                console.log('res', res);
                setDefaultPaymentID("")
            } else {
                console.log("error", res);
                setMethodLoad(false)
            }
        })
    };

    const removeMethod = (id) => {

        confirm({
            wrapClassName: "modal-generic cancel-event-modal min-modal-600",
            // title: 'Warning',
            centered: true,
            closable: true,
            icon: <ExclamationCircleOutlined />,
            closeIcon: <FontAwesomeIcon icon={faXmark} />,
            content: "Are you sure you want to remove shipping status ?",
            okText: " Yes, Remove",
            okType: "danger",
            cancelText: " No, I don't want to Remove",
            onOk() {
                dispatch(removeShippingMethod(id)).then((res) => {
                    if (res?.success) {
                        notification.success({
                            message: "Shipping Status Has Been Removed",
                            className: "toast-success",
                        });
                        dispatch(getShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });
                        dispatch(getAllShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });

                        console.log('res', res);
                    } else {
                        notification.success({
                            message: "Shipping Status Has Been Removed",
                            className: "toast-success",
                        });
                        console.log("error", res);
                    }
                })
            },
            onCancel() {
                console.log("cancel Delete");
            },
        });

    };


    const updateMethod = (id, item) => {
        const updatedData = {
            id: item._id,
            name: item.name,
            amount: item.amount,
            is_default: true
        };

        confirm({
            wrapClassName: "modal-generic cancel-event-modal min-modal-600",
            // title: 'Warning',
            centered: true,
            closable: true,
            icon: <ExclamationCircleOutlined />,
            closeIcon: <FontAwesomeIcon icon={faXmark} />,
            content: "Are you sure you want to change shipping status ?",
            okText: " Yes, Change",
            okType: "success",
            cancelText: " No, I don't want to Change",
            onOk() {
                dispatch(updateShippingMethod(updatedData)).then((res) => {
                    if (res?.success) {
                        notification.success({
                            message: "Shipping Status Has Been Updated",
                            className: "toast-success",
                        });
                        dispatch(getShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });
                        console.log('res', res);
                    } else {
                        console.log("error", res);
                    }
                })
            },
            onCancel() {
                console.log("cancel Delete");
            },
        });

    };


    const addMethod = (id) => {

        confirm({
            wrapClassName: "modal-generic cancel-event-modal min-modal-600",
            // title: 'Warning',
            centered: true,
            closable: true,
            icon: <ExclamationCircleOutlined />,
            closeIcon: <FontAwesomeIcon icon={faXmark} />,
            content: "Are you sure you want to add shipping status ?",
            okText: " Yes, Add",
            okType: "success",
            cancelText: " No, I don't want to Add",
            onOk() {
                dispatch(addShippingMethod(id)).then((res) => {
                    if (res?.success) {
                        notification.success({
                            message: "Shipping Status Has Been Added",
                            className: "toast-success",
                        });
                        dispatch(getShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });
                        dispatch(getAllShippingSetup()).then(() => {
                            setCardLoading(false);
                        }).catch((err) => {
                            setCardLoading(false);
                        });

                        console.log('res', res);
                    } else {
                        console.log("error", res);
                    }
                })
            },
            onCancel() {
                console.log("cancel Delete");
            },
        });

    };

    //   const editMethodDates = () => {
    //     setLoad(true)
    //     dispatch(editPaymentMethod(methodID, expMonth, expYear)).then((res) => {
    //       if (res.success) {
    //         notification.success({
    //           message: "Expiry Date Has Been Updated",
    //           className: "toast-success",
    //         });
    //         setShowModal(false)
    //         setLoad(false)
    //         setCardLoading(true)
    //         dispatch(getAffiliateCards()).then(() => {
    //           setCardLoading(false);
    //         }).catch((err) => {
    //           setCardLoading(false);
    //         });
    //       } else {
    //         setLoad(false)
    //       }
    //     }).catch((err) => {
    //       setLoad(false)
    //       notification.error({
    //         message: "Invalid Expiry Year",
    //         className: "toast-error",
    //       });
    //     })
    //   }


    //   function dataCardDetail() {
    //     let data = affiliateCards?.message?.data;
    //     if (data) {
    //       return (
    //         <>
    //           <div className="amount-box">
    //             {data.map((item, i) => {
    //               return (
    //                 <>
    //                   <div className="deposit_card">
    //                     <input
    //                       type="radio"
    //                       name="card"
    //                       id={item.id}
    //                       class="infchecked"
    //                       value={item.id}
    //                       defaultChecked={i === 0 ? true : false}
    //                       onChange={() => {
    //                         setDefaultPaymentID(item?.id)
    //                       }}
    //                     />
    //                     <label for={item.id}>
    //                       <div className="pull-left">
    //                         <span className="card-name">{item.card.brand}</span>{" "}
    //                         ending in {item.card.last4}
    //                       </div>
    //                       <div className="text-right">
    //                         <span className="card-expired">
    //                           {" "}
    //                           Expired: {item.card.exp_month}/{item.card.exp_year}
    //                         </span>
    //                         {/* <span className="fa fa-pencil edit-icon ml-2"></span>
    //                           <span className="fa fa-trash ml-2"></span> */}
    //                       </div>
    //                     </label>
    //                     <Buttond
    //                       className="default-btn red-btn payment-btns ml-3 width-auto d-flex align-items-center justify-content-center"
    //                       type="primary"
    //                       size="small"
    //                       key="cancel"
    //                       onClick={() => deleteMethod(item?.id)}
    //                       icon={
    //                         <FontAwesomeIcon
    //                           size="sm"
    //                           className="event-icon"
    //                           icon={faTrash}
    //                         />
    //                       }
    //                     ></Buttond>
    //                     <Buttond
    //                       className="default-btn payment-btns width-auto d-flex align-items-center justify-content-center"
    //                       type="primary"
    //                       size="small"
    //                       key="cancel"
    //                       onClick={() => { setShowModal(true); setMethodID(item?.id); setExpMonth(item?.card?.exp_month); setExpYear(item?.card?.exp_year); }}
    //                       // disabled
    //                       icon={
    //                         <FontAwesomeIcon
    //                           size="sm"
    //                           className="event-icon"
    //                           icon={faPen}
    //                         />
    //                       }
    //                     ></Buttond>
    //                   </div>
    //                 </>
    //               );
    //             })}
    //           </div>
    //           <div className="d-flex justify-content-between align-items-center">
    //             <div className="amount-box pt-0">
    //               <Button
    //                 className="default-btn ml-0 fw-normal"
    //                 onClick={() => paymentMethod()}
    //               >
    //                 Add Card
    //               </Button>
    //             </div>
    //             <div className="amount-box pt-0">
    //               <Buttond
    //                 loading={methodLoad}
    //                 className="default-btn ml-0 fw-normal"
    //                 onClick={() => setMethodDefault()}
    //                 //disabled={defaultPaymentID ? false : true}
    //                disabled={defaultPaymentID ? false : true}

    //               >
    //                 Save Method
    //               </Buttond>
    //             </div>
    //           </div>
    //           <Modal
    //             maskClosable={false}
    //             className="modal-generic modal-500"
    //             centered
    //             open={showModal}
    //             onCancel={() => setShowModal(false)}
    //             closeIcon={<FontAwesomeIcon icon={faXmark} />}
    //             footer={[
    //               <Buttond
    //                 key="submit"
    //                 type="primary"
    //                 className="ml-3 default-btn"
    //                 loading={load}
    //                 disabled={!expMonth || !expYear}
    //                 onClick={() => {
    //                   editMethodDates();
    //                 }}
    //               >
    //                 Update
    //               </Buttond>,
    //               <Button type="primary" className="default-btn outline" onClick={() => { setShowModal(false); setExpMonth(""); setExpYear(""); }}>
    //                 Cancel
    //               </Button>
    //             ]}
    //           >
    //             <h2 className="modal-hd1">Update Expiry Dates</h2>
    //             <div className="row form-style-new">
    //               <div className="col-12 mb-15">
    //                 <h6>Expiry Month</h6>
    //                 <InputNumber
    //                   className="w-100 select-modal"
    //                   size="large"
    //                   value={expMonth}
    //                   parser={(value) => {
    //                     return value.substring(0, 2)
    //                   }}
    //                   min={1}
    //                   max={31}
    //                   onChange={(e) => {
    //                     {
    //                       setExpMonth(e)
    //                     }
    //                   }}
    //                 />
    //               </div>
    //               <div className="col-12 mb-15">
    //                 <h6>Expiry Year</h6>
    //                 <InputNumber
    //                   className="w-100 select-modal"
    //                   size="large"
    //                   value={expYear}
    //                   parser={(value) => {
    //                     return value.substring(0, 4)
    //                   }}
    //                   min={1}
    //                   // max={100}
    //                   onChange={(e) => {
    //                     {
    //                       setExpYear(e)
    //                     }
    //                   }}
    //                 />
    //               </div>
    //             </div>
    //             {/* <div className="my-4 d-flex justify-content-center align-items-center">
    //               <div>
    //                 <h6>Expiry Month</h6>
    //                 <InputNumber
    //                   className="w-50 select-modal"
    //                   size="large"
    //                   value={expMonth}
    //                   parser={(value) => {
    //                     return value.substring(0, 2)
    //                   }}
    //                   min={1}
    //                   max={31}
    //                   onChange={(e) => {
    //                     {
    //                       setExpMonth(e)
    //                     }
    //                   }}
    //                 />
    //               </div>

    //               <div>
    //                 <h6>Expiry Year</h6>
    //                 <InputNumber
    //                   className="w-50 select-modal"
    //                   size="large"
    //                   value={expYear}
    //                   parser={(value) => {
    //                     return value.substring(0, 4)
    //                   }}
    //                   min={1}
    //                   // max={100}
    //                   onChange={(e) => {
    //                     {
    //                       setExpYear(e)
    //                     }
    //                   }}
    //                 />
    //               </div>
    //             </div> */}
    //           </Modal>
    //         </>
    //       );
    //     }
    //   }

    return (
        <>
            <div class="title-area">
                <h1 class="page-title">Global Shipping</h1>
            </div>


            <div className="container p-0 ml-0">
                <div className="row">
                    <div className="col-lg-6 col-xl-7">
                        <div className="stream-box-white">
                        <div class="data-listing-box check-style border-btm">
                                <h4 class="ship-title">Shipping Mode</h4>
                                <div className="check-right">
                                    <div className="ship-hds">
                                        <div className="select-ship text-center">
                                            Select
                                        </div>
                                    </div>

                                </div>


                            </div>
                            {!getAllShipping?.loading ? (
                                <>
                                    {getAllShipping?.payload?.data.map((item, i) => {
                                        return (
                                            <>
                                                <div class="data-listing-box check-style">
                                                    <h4 class="count-title">{item.name}</h4>
                                                    <h3 class="count ">${item.amount}
                                                        <Checkbox
                                                            onChange={(e) =>
                                                               { if (!item.selected){
                                                                addMethod(item._id)}}}
                                                            checked={item.selected}
                                                            size="large"
                                                        >
                                                        </Checkbox>
                                                    </h3>

                                                </div>

                                            </>
                                        )
                                    })}

                                    {/* <div className="amount-box">
                          <>
                            <div className="amount-box pt-0">
                              <Button
                                className="default-btn ml-0 fw-normal"
                                onClick={() => paymentMethod()}
                                disabled={true}
              
                              >
                                Save
                              </Button>
                            </div>
                          </>

                  </div> */}
                                </>
                            ) : (
                                <span className="spinclass loader-center position-relative">
                                    <Spin size="large" />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            <div class="title-area">
                <h1 class="page-title">User Shipping</h1>
            </div>




            <div className="container p-0 ml-0">
                <div className="row">
                    <div className="col-lg-6 col-xl-7">
                        <div className="stream-box-white">
                            <div class="data-listing-box check-style border-btm">
                                <h4 class="ship-title">Shipping Mode</h4>
                                <div className="check-right">
                                    <div className="ship-hds">
                                        <div className="select-ship">
                                            Select
                                        </div>
                                        <div className="remove-ship">Remove</div>
                                    </div>

                                </div>


                            </div>
                            {!shippingSetup?.loading ? (
                                <>
                                    {shippingSetup?.payload?.data.map((item, i) => {
                                        return (
                                            <>
                                                <div class="data-listing-box check-style">
                                                    <h4 class="count-title">{item.name}</h4>
                                                    <div className="check-right">
                                                        <div className="count">
                                                            <h3 className="value-box mb-0">
                                                                <div className="text-right">
                                                                ${item.amount}
                                                                </div>
                                                                <Radio
                                                                    onChange={(e) => updateMethod(e, item)}
                                                                    checked={item.is_default}
                                                                >
                                                                </Radio>
                                                            </h3>
                                                            <div className="cross-btn-main">
                                                            <button className="cross-btn" onClick={(e) => removeMethod(item._id)}> <i class="fa fa-times"></i></button>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                            </>
                                        )
                                    })}
                                    {/* <h5 className="mb-15 mt-10"></h5> */}
                                    {/* <div className="amount-box">
                          <>
                            <div className="amount-box pt-0">
                              <Button
                                className="default-btn ml-0 fw-normal"
                                onClick={() => paymentMethod()}
                                disabled={true}
              
                              >
                                Save
                              </Button>
                            </div>
                          </>

                  </div> */}
                                </>
                            ) : (
                                <span className="spinclass loader-center position-relative">
                                    <Spin size="large" />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
