import {
  GET_CHAT_TOKEN,
  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  GET_CHAT_ERROR,
  SAVE_CHAT_REQUEST,
  SAVE_CHAT_SUCCESS,
  SAVE_CHAT_ERROR,
  GET_REVIEW_CHAT_REQUEST,
  GET_REVIEW_CHAT_SUCCESS,
  GET_REVIEW_CHAT_ERROR,
  SAVE_REVIEW_CHAT_REQUEST,
  DELETE_CHAT_REQUEST,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT_ERROR,
  DELETE_REVIEW_CHAT_REQUEST,
  DELETE_REVIEW_CHAT_SUCCESS,
  DELETE_REVIEW_CHAT_ERROR,
  BAN_USER_REQUEST,
  BAN_USER_SUCCESS,
  BAN_USER_ERROR, 
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_ERROR, GET_CHAT_LOG_REQUEST, GET_CHAT_LOG_SUCCESS, GET_CHAT_LOG_ERROR,
  UNBAN
} from '../types/types';
// import { BASEURL } from '../../config';
import axios from 'axios';

export const createChatToken = (event_id) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `chat/gettoken`,
    data: { event_id },
    
    // headers: {
    // 	Accept: "application/json",
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_CHAT_TOKEN,
        payload: response.data?.message,
      });
    })
    .catch((error) => {
      console.log(error,"error");
      // alert(JSON.stringify(error.response));
    });
};

export const getChat = (event_id) => (dispatch) => {
  dispatch({ type: GET_CHAT_REQUEST });
  return axios({
    method: 'POST',
    url: `chat/messages`,
    
    data: { event_id },
  })
    .then((response) => {
      return dispatch({
        type: GET_CHAT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CHAT_ERROR,
        payload: error.response,
      });
    });
};

export const saveChat = (event_id, message) => (dispatch) => {
  dispatch({ type: SAVE_CHAT_REQUEST });
  return axios({
    method: 'POST',
    url: `chat/save`,
    
    data: { event_id, message },
  })
    .then((response) => {
      return dispatch({
        type: SAVE_CHAT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_CHAT_ERROR,
        payload: error.response,
      });
    });
};

export const getReviewChat = (review_id) => (dispatch) => {
  dispatch({ type: GET_REVIEW_CHAT_REQUEST });
  return axios({
    method: 'POST',
    url: `reviewchat/messages?page=1&limit=1000`,
    
    data: { review_id },
  })
    .then((response) => {
      return dispatch({
        type: GET_REVIEW_CHAT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_REVIEW_CHAT_ERROR,
        payload: error.response,
      });
    });
};

export const saveReviewChat = (data) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `reviewchat/save`,
    data,
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    });
};

export const deleteChat = (data) => (dispatch) => {
  dispatch({ type: DELETE_CHAT_REQUEST });
  return axios({
    method: 'POST',
    url: `chat/deletemessage`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    data:  data,
  })
    .then((response) => {
      return dispatch({
        type: DELETE_CHAT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_CHAT_ERROR,
        payload: error.response,
      });
    });
};

export const deleteReviewChat = (chat_id) => (dispatch) => {
  dispatch({ type: DELETE_REVIEW_CHAT_REQUEST });
  return axios({
    method: 'POST',
    url: `reviewchat/deletemessage`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: { chat_id },
  })
    .then((response) => {
      return dispatch({
        type: DELETE_REVIEW_CHAT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_REVIEW_CHAT_ERROR,
        payload: error.response,
      });
    });
};

export const banUser = (data) => (dispatch) => {
  dispatch({ type: BAN_USER_REQUEST });
  return axios({
    method: 'POST',
    url: `users/chat/banuser`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    data:  data,
  })
    .then((response) => {
      return dispatch({
        type: BAN_USER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: BAN_USER_ERROR,
        payload: error.response,
      });
    });
};   

export const unBan = (data) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `users/chat/unbanuser`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    data,
  })
    .then((response) => {
      dispatch({
        type: UNBAN,
        payload: response.data,
      });
      return response.data
    })
    .catch((error) => {
      return error
    });
};


export const deleteComment = (chat_id) => (dispatch) => {
  dispatch({ type: DELETE_COMMENT_REQUEST });
  return axios({
    method: 'POST',
    url: `chat/deletecomment`,
    // headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: { chat_id },
  })
    .then((response) => {
      return dispatch({
        type: DELETE_COMMENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_COMMENT_ERROR,
        payload: error.response,
      });
    });
};

export const getChatLog = (event_id) => (dispatch) => {
  dispatch({ type: GET_CHAT_LOG_REQUEST });
  return axios({
    method: 'POST',
    url: `chat/logs`,
    
    data: { event_id },
  })
    .then((response) => {
      return dispatch({
        type: GET_CHAT_LOG_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CHAT_LOG_ERROR,
        payload: error.response,
      });
    });
};