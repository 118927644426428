import { BOOST_REVIEW, DE_BOOST_REVIEW, GET_BOOST_DETAIL, AD_COST } from "../types/types";
import axios from "axios";

export const boostReview = (review_id, budget, number_of_days, start_date, end_date) => (dispatch) => {
  return axios({
    method: "POST",
    url: `reviews/promotereviews`,
    data: {
      review_id,
      budget,
      number_of_days,
      start_date,
      end_date
    }
  })
    .then((response) => {
      dispatch({
        type: BOOST_REVIEW,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateBoostReview = (review_id, budget, number_of_days, start_date, end_date) => (dispatch) => {
  return axios({
    method: "POST",
    url: `reviews/updatepromoteReviews`,
    data: {
      review_id,
      budget,
      number_of_days,
      start_date,
      end_date
    }
  })
    .then((response) => {
      dispatch({
        type: BOOST_REVIEW,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getBoostDetails = (review_id) => (dispatch) => {
  return axios({
    method: "POST",
    url: `reviews/getpromotereviews`,
    data: {
      review_id,
    }
  })
    .then((response) => {
      dispatch({
        type: GET_BOOST_DETAIL,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deBoostReview = (review_id) => (dispatch) => {
  return axios({
    method: "POST",
    url: `reviews/deactivate`,
    data: {
      review_id
    }
  })
    .then((response) => {
      dispatch({
        type: DE_BOOST_REVIEW,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAdCost = () => (dispatch) => {
  return axios({
    method: "POST",
    url: `reviews/adscost`,
  })
    .then((response) => {
      dispatch({
        type: AD_COST,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

