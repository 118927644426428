import axios from 'axios';
import {
  DELETE_EVENT_ERROR, DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS, DELETE_SHOW_ERROR, DELETE_SHOW_REQUEST,
  DELETE_SHOW_SUCCESS, GET_BRAND_PUBLISH_EVENTS_ERROR, GET_BRAND_PUBLISH_EVENTS_REQUEST,
  GET_BRAND_PUBLISH_EVENTS_SUCCESS, GET_BRAND_RECORD_EVENTS_ERROR, GET_BRAND_RECORD_EVENTS_REQUEST,
  GET_BRAND_RECORD_EVENTS_SUCCESS, GET_EVENT_ERROR, GET_EVENT_ID_ERROR, GET_EVENT_ID_REQUEST,
  GET_EVENT_ID_SUCCESS, GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS, GET_SHOW_ID_ERROR, GET_SHOW_ID_REQUEST,
  GET_SHOW_ID_SUCCESS, GET_SHOW_RECORD_EVENTS_ERROR, GET_SHOW_RECORD_EVENTS_REQUEST, GET_SHOW_RECORD_EVENTS_SUCCESS, HOST_EVENT_ERROR, HOST_EVENT_REQUEST, HOST_EVENT_SUCCESS, POST_EVENT_ERROR, POST_EVENT_REQUEST,
  POST_EVENT_SUCCESS, POST_SHOW_ERROR, POST_SHOW_REQUEST, POST_SHOW_SUCCESS, PUBLISH_EVENT_ERROR, PUBLISH_EVENT_REQUEST,
  PUBLISH_EVENT_SUCCESS, PUBLISH_SHOW_ERROR, PUBLISH_SHOW_REQUEST, PUBLISH_SHOW_SUCCESS, UPCOMING_EVENT_ERROR, UPCOMING_EVENT_REQUEST,
  UPCOMING_EVENT_SUCCESS, UPDATE_EVENT_ERROR, UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS, UPDATE_SHOW_ERROR, UPDATE_SHOW_REQUEST, UPDATE_SHOW_SUCCESS
} from '../types/types';
const token = JSON.parse(localStorage.getItem('token'));
export const postEvent = (data) => (dispatch) => {
  dispatch({ type: POST_EVENT_REQUEST });
  return axios({
    method: 'POST',
    url: `events/createnewscheduleevent`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getEvents = (limit, page, title, sort) => (dispatch) => {
  let status = '';
  dispatch({ type: GET_EVENT_REQUEST });

  if (title === 'Live Events') {
    status = 'live';
  }
  if (title === 'Upcoming') {
    status = 'scheduled';
  }
  if (title === 'Recorded') {
    status = 'recorded';
  }
  if (title === 'scheduled,live') {
    status = 'scheduled,live';
  }

  return axios({
    method: 'GET',
    url: `events/getallevents?limit=${limit}&page=${page}&status=${status}&sortby=${status === 'recorded' ? sort : ''
      }`,

  })
    .then((response) => {
      return dispatch({
        type: GET_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getBrandRecordedEvents = (limit, page, title, sort, startData, endData, gender, category, subCategory, eventID) => (dispatch) => {
  dispatch({ type: GET_BRAND_RECORD_EVENTS_REQUEST });
  return axios({
    method: 'POST',
    url: `events/getbrandrecordedevents`,
    data: {
      from_date: startData,
      to_date: endData,
      gen_category_id: gender ? gender : "",
      category_id: category ? category : "",
      sub_category_id: subCategory ? subCategory : "",
      source: title.toLowerCase(),
      event_counter: eventID ? eventID.replaceAll("-", "") : ""
    },
    params: {
      limit,
      page,
      sortby: sort
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_BRAND_RECORD_EVENTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_BRAND_RECORD_EVENTS_ERROR,
        payload: error.response,
      });
    });
};

export const getBrandPublishedEvents = (limit, page, title, sort, startData, endData, gender, category, subCategory, eventID) => (dispatch) => {
  dispatch({ type: GET_BRAND_PUBLISH_EVENTS_REQUEST });
  return axios({
    method: 'POST',
    url: `events/getbrandrecordedevents`,
    data: {
      from_date: startData,
      to_date: endData,
      gen_category_id: gender ? gender : "",
      category_id: category ? category : "",
      sub_category_id: subCategory ? subCategory : "",
      source: title.toLowerCase(),
      event_counter: eventID ? eventID.replaceAll("-", "") : ""
    },
    params: {
      limit,
      page,
      sortby: sort
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_BRAND_PUBLISH_EVENTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_BRAND_PUBLISH_EVENTS_ERROR,
        payload: error.response,
      });
    });
};

//////Show Get Records
export const getShowRecordedEvents = (limit, page, title, sort, startData, endData, showID, flag) => (dispatch) => {
  if (!flag) {
    dispatch({ type: GET_SHOW_RECORD_EVENTS_REQUEST });
  }
  return axios({
    method: 'POST',
    url: `shows/getrecordedshows`,

    data: {
      from_date: startData,
      to_date: endData,
      // gen_category_id: gender ? gender : "",
      // category_id: category ? category : "",
      // sub_category_id: subCategory ? subCategory : "",
      source: title.toLowerCase(),
      show_counter: showID ? showID.replaceAll("-", "") : ""
    },
    params: {
      limit,
      page,
      sortby: sort
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_SHOW_RECORD_EVENTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SHOW_RECORD_EVENTS_ERROR,
        payload: error.response,
      });
    });
};

/////Show Create Events 
export const postShow = (data) => (dispatch) => {
  dispatch({ type: POST_SHOW_REQUEST });
  return axios({
    method: 'POST',
    url: `shows/uploadrecordedshow`,
    headers: {

      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_SHOW_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_SHOW_ERROR,
        payload: error.response,
      });
    });
};

////Publish Show
export const publishShow = (id, status) => (dispatch) => {
  dispatch({ type: PUBLISH_SHOW_REQUEST });
  return axios({
    method: 'POST',
    url: `shows/publishorunpublishshow`,

    data: { id: id, status: status },
  })
    .then((response) => {
      return dispatch({
        type: PUBLISH_SHOW_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: PUBLISH_SHOW_ERROR,
        payload: error.response,
      });
    });
};

export const getShowBy_Id = (id, room) => (dispatch) => {
  dispatch({ type: GET_SHOW_ID_REQUEST });

  return axios({
    method: 'GET',
    url: `shows/getoneshow/${id}?room=${room ? 1 : 0}`,

    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_SHOW_ID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SHOW_ID_ERROR,
        payload: error.response,
      });
    });
};

export const deleteShow = (id) => (dispatch) => {
  dispatch({ type: DELETE_SHOW_REQUEST });
  return axios({
    method: 'DELETE',
    url: `shows/deleterecordedshow/${id}`,

  })
    .then((response) => {
      return dispatch({
        type: DELETE_SHOW_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_SHOW_ERROR,
        payload: error.response,
      });
    });
};

export const UpdateShows = (data, id) => (dispatch) => {
  dispatch({ type: UPDATE_SHOW_REQUEST });
  return axios({
    method: 'PUT',
    url: `shows/updaterecordedshow/${id}`,

    // headers: {
    //     Accept: "application/json",
    //     Authorization: "Bearer " + token,
    // },
    data: data,
  })
    .then((response) => {
      return dispatch({ type: UPDATE_SHOW_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_SHOW_ERROR,
        payload: error.response,
      });
    });
};

export const getEventsBy_Id = (id, room) => (dispatch) => {
  dispatch({ type: GET_EVENT_ID_REQUEST });

  return axios({
    method: 'GET',
    url: `events/getoneevent/${id}?room=${room ? 1 : 0}`,

    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_EVENT_ID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_EVENT_ID_ERROR,
        payload: error.response,
      });
    });
};

export const deleteEvent = (id) => (dispatch) => {
  dispatch({ type: DELETE_EVENT_REQUEST });
  return axios({
    method: 'DELETE',
    url: `events/deletescheduleevent/${id}`,

  })
    .then((response) => {
      return dispatch({
        type: DELETE_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const deleteEventRecord = (id) => (dispatch) => {
  dispatch({ type: DELETE_EVENT_REQUEST });
  return axios({
    method: 'DELETE',
    url: `events/deleterecordedevent/${id}`,

  })
    .then((response) => {
      return dispatch({
        type: DELETE_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const UpdateEvents = (data, id) => (dispatch) => {
  dispatch({ type: UPDATE_EVENT_REQUEST });
  return axios({
    method: 'PUT',
    url: `events/updatescheduleevent/${id}`,

    // headers: {
    //     Accept: "application/json",
    //     Authorization: "Bearer " + token,
    // },
    data: data,
  })
    .then((response) => {
      return dispatch({ type: UPDATE_EVENT_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getUpcomingEvents = (limit, page) => (dispatch) => {
  dispatch({ type: UPCOMING_EVENT_REQUEST });

  return axios({
    method: 'GET',
    url: `events/getupcomingevents?limit=${limit}&page=${page}`,

  })
    .then((response) => {
      return dispatch({
        type: UPCOMING_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPCOMING_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const publishEvent = (id, status) => (dispatch) => {
  dispatch({ type: PUBLISH_EVENT_REQUEST });
  return axios({
    method: 'POST',
    url: `events/publishorunpublishevent`,

    data: { id: id, status: status },
  })
    .then((response) => {
      return dispatch({
        type: PUBLISH_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: PUBLISH_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const hostCheck = (id, host_status) => (dispatch) => {
  dispatch({ type: HOST_EVENT_REQUEST });
  return axios({
    method: 'POST',
    url: `events/updatehoststatus`,

    data: { id: id, host_status: host_status },
  })
    .then((response) => {
      return dispatch({
        type: HOST_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: HOST_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const showsBulkUpload = (data) => async (dispatch) => {
  const res = await axios.post(`shows/bulkuploadshows`, data);
  return res.data;
};

export const showErrMessage = (data, TOKEN) => async (dispatch) => {
  const res = await axios({
    url: 'shows/updatebulkshowerrormsg',
    method: "post",
    headers: { Authorization: `Bearer ${TOKEN ? TOKEN : token}` },
    data: { data }
  })
  // const res = await axios.post(`shows/updateBulkShowErrorMsg`, { data });
  return res.data;
};