import { LoadingOutlined } from "@ant-design/icons";
import {
  faAngleLeft,
  faBan,
  faBoxOpen,
  faCode,
  faHeart,
  faPenToSquare,
  faTag,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, notification, Select, Skeleton, Spin, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import "../../css/controlRoom.scss";
import { getReviewBy_Id } from "../../redux/actions/influencerReview";
// import { useSearchParams } from "react-router-dom";
import { getReviewChat } from "../../redux/actions/chat";
import useMediaQuery from "../../reUsable/useMediaQuery";
import ReactPlayer from "react-player";
import numeral from "numeral";
import RecordedShowChat from "../shows/recordedShowChat";

const { TabPane } = Tabs;

function RecordedReview() {
  const mediaHeight = useMediaQuery("(max-width: 1280px)");

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [top, setTop] = useState(15);
  const dispatch = useDispatch();
  const location = useLocation();
  const [spinner, setSpin] = useState(true);
  const [cameraPreview, setCameraPreview] = useState(false);
  const [startbroadcast, setStartBroadcast] = useState(false);
  const [dateTime, setDate] = useState("");
  const [sku, setSku] = useState([]);
  const [banner, setBanner] = useState("");
  const [title, setTitle] = useState("");
  const [recorded, setRecorded] = useState("");
  const [liveData, setLiveData] = useState("");
  const [modal2Visible, settingsModal] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const [eventEnded, setEventEnded] = useState(false);
  const [mirror, setMirror] = useState(false);
  const [mute, setMute] = useState(false);
  const [error, setError] = useState("");
  const [endSession, setEndSession] = useState(false);
  const [endLiveLoading, setEndLiveLoading] = useState(false);
  const [settingPreview, setSettingPreview] = useState(false);
  const [videoDevices, setVideoDevices] = useState();
  const [audioDevices, setAudioDevices] = useState();
  const [audioOutputDevices, setAudioOutputDevices] = useState();
  const [screenView, setScreenView] = useState("landscape");
  const [mirrorVideo, setMirrorVideo] = useState(true);
  const [videoDeviceID, setVideoDeviceID] = useState("");
  const [audioMicrophoneID, setAudioMicrophoneID] = useState("");
  const [controlData, setControlData] = useState("");
  const [chat, setChat] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const [height, setHeight] = useState(440);

  const { getReviewById, validate } =
    useSelector((state) => {
      return state;
    });

  const params = useParams();
  const id = params.id;

  const chatRef = useRef();
  useEffect(() => {
    if (id) {
      dispatch(getReviewBy_Id(id)).then((res) => {
        if (res.payload.success) {
          setSpin(false);
          setEventEnded(res.payload?.message);
          setRecorded(res.payload?.message[0]?.media_url);
          setBanner(res.payload?.message[0]?.banner);
          setDate(res.payload?.message[0]?.start_date);
          setTitle(res.payload?.message[0]?.title);

          setTimeout(() => {
            const resizeObserver = new ResizeObserver((event) => {
              setHeight(event[0].contentBoxSize[0].blockSize);
            });

            if (chatRef && chatRef?.current) {
              resizeObserver.observe(chatRef.current);
            }
          }, 500);
        }
      });
    }
  }, [chatRef, id]);

  const onKeyChange = (key) => {
    if (key == "1" && id) {
      setChatLoading(true);
      dispatch(getReviewChat(id)).then((res) => {
        if (res.payload.success) {
          setChatLoading(false);
          setChat(res.payload.message?.data);
        } else {
          setChatLoading(false);
          notification.error({
            message: res.payload.data.message,
            className: "toast-error",
          });
        }
      });
    }
  };

  const myPlayer = useMemo(() => {
    return (
      // <ReactHlsPlayer
      //   src={recorded}
      //   autoPlay={true}
      //   controls={true}
      //   loop={true}
      // />
      <ReactPlayer
        playing
        // className="review_react_player"
        width={"100%"}
        height={"100%"}
        url={recorded}
        // loop={true}
        controls
      />
    );
  }, [recorded]);

  if (!spinner) {
    return (
      <>
        {eventEnded.length === 0 ? (
          <div className="no-result-found event-ended">
            <div className="no-result-img">
              <img
                src={validate?.payload?.message?.profile_image_url}
                alt="searchEventImg"
              />
            </div>
            {/* <h3 className="no-event-hd"> Event has ended.</h3> */}
            <p className="event-para mb-20">Event has ended.</p>
            <Link
              to={"/influencer-review?type=publish"}
              className="default-btn d-inline-flex ff-nunito"
            >
              <span> Go to reviews</span>
            </Link>
          </div>
        ) : (
          // <div className="event-ended d-flex align-items-center justify-content-center">
          //   Event has ended
          // </div>

          <>
            <div className="control-room-top-area mb-2">
              <div className="control-top-box">
                <Link
                  // to={
                  //   validate?.payload?.message?.account_type === "brand"
                  //     ? `/events?type=${location?.state?.pageName}`
                  //     : `/host-event?type=${location?.state?.pageName}`
                  // }
                  to={location?.state?.pageName === "CreatorReviews" ? "/ugc-reviews?type=publish" : "/influencer-review?type=publish"}
                  className="prev-next-link ff-nunito"
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                  <span>Go back</span>
                </Link>
                {/* </a> */}
              </div>
            </div>

            <div className="event-preview-box-main mb-20">
              {!getReviewById.loading ? (
                <div className="row">
                  <div className="col-md-2 col-lg-2 d-lg-flex align-items-center">
                    {/* <div className="event-banner" style={{ backgroundImage: `url(${getReviewById?.payload?.message[0]?.banner})` }} > */}
                    <div className="event-banner w-md-100">
                      <div className="event-box w-100 h-100 overflow-hidden">
                        <div className="event-banner-img">
                          <img
                            src={getReviewById?.payload?.message[0]?.banner}
                            alt="banner"
                          />
                        </div>
                        <div>
                          <div className="schedule-badge">Recorded</div>
                        </div>

                        {/* <div className="d-flex justify-content-end">
                  <div className="ribbon-small">
                    <FontAwesomeIcon icon={faFlask} />
                    <span>Event </span>
                  </div>
                </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-lg-2 d-lg-flex  preview-data-border border-left-0 align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Scheduled</h4>
                      <h5 className="preview-date">
                        {" "}
                        {moment(getReviewById?.payload?.message[0]?.created_at)
                          .utc()
                          .format("MM-DD-YYYY")}
                      </h5>
                      <h6 className="preview-time">
                        At{" "}
                        {moment(
                          getReviewById?.payload?.message[0]?.created_at
                        ).format("hh:mm a")}
                      </h6>
                    </div>
                  </div>

                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Start Date</h4>
                      <h5 className="preview-date">
                        {" "}
                        {moment(getReviewById?.payload?.message[0]?.created_at)
                          .utc()
                          .format("MM-DD-YYYY")}
                      </h5>
                      <h6 className="preview-time">
                        At{" "}
                        {moment(
                          getReviewById?.payload?.message[0]?.created_at
                        ).format("hh:mm a")}
                      </h6>
                    </div>
                  </div>

                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">End Date</h4>
                      <h5 className="preview-date">
                        {" "}
                        {moment(getReviewById?.payload?.message[0]?.created_at)
                          .utc()
                          .format("MM-DD-YYYY")}
                      </h5>
                      <h6 className="preview-time">
                        At{" "}
                        {moment(
                          getReviewById?.payload?.message[0]?.created_at
                        ).format("hh:mm a")}
                      </h6>
                    </div>
                  </div>

                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Duration</h4>
                      <h5 className="preview-date">
                        {/* &nbsp; */}
                        {moment
                          .utc(
                            moment(
                              getReviewById?.payload?.message[0]?.created_at,
                              "HH:mm:ss"
                            ).diff(
                              moment(
                                getReviewById?.payload?.message[0]?.created_at,
                                "HH:mm:ss"
                              )
                            )
                          )
                          .format("HH:mm:ss")}
                      </h5>
                      <h6 className="preview-time">&nbsp;</h6>
                    </div>
                  </div>

                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center pl-xl-0">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Event</h4>
                      <h5
                        className="preview-date"
                        title={getReviewById?.payload?.message[0]?.title}
                      >
                        {getReviewById?.payload?.message[0]?.title.length > 30
                          ? getReviewById?.payload?.message[0]?.title.slice(
                            0,
                            30
                          ) + "..."
                          : getReviewById?.payload?.message[0]?.title}
                      </h5>
                      {/* <h6 className="preview-time event-detail-txt">
                        <CalendarFilled /> LIVE-EVENT
                      </h6> */}
                      {/* <h6 className="preview-time">LIVE-EVENT</h6> */}
                      {/* <div className="event-detail-txt">
                        <CalendarFilled /> LIVE-EVENT
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="col-lg-4 preview-data-border d-lg-flex align-items-center">
                    <div className="preview-date-main no-border-bottom">
                      <h4 className="date-txt notes">Details</h4>
                      <h6 className="preview-time notes-txt">
                        {getReviewById?.payload?.message[0]?.event?.notes !==
                          "undefined"
                          ? getReviewById?.payload?.message[0]?.event?.notes
                          : "-"}
                      </h6>
                    </div>
                  </div> */}
                </div>
              ) : (
                // <Spin className="m-auto d-table" indicator={antIcon} />
                <div className="event-detail-skeleton">
                  <Skeleton
                    avatar={{
                      size: "large",
                      shape: "square",
                    }}
                    active
                    paragraph={{
                      rows: 2,
                    }}
                  />
                </div>
              )}
            </div>

            <div className="control-boxes-main">
              <div className="control-boxes-box" ref={chatRef}>
                <div className="recorded-main-grid">
                  <div className="recorded-left">
                    <div className="live-stats-main">
                      <div className="live-stats-box">
                        <div className="stats-icon">
                          <FontAwesomeIcon
                            className="text-info "
                            icon={faUsers}
                          />
                        </div>
                        <div className="stats-value">
                          {getReviewById?.payload?.message[0]?.views
                            ? getReviewById?.payload?.message[0]?.views
                            : "0"}
                        </div>
                        <div className="stats-hd">Viewers</div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon">
                          <FontAwesomeIcon
                            className="text-danger "
                            icon={faHeart}
                          />
                        </div>
                        <div className="stats-value">
                          {getReviewById?.payload?.message[0]?.likes
                            ? getReviewById?.payload?.message[0]?.likes
                            : "0"}
                        </div>
                        <div className="stats-hd">Likes</div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon tag">
                          <FontAwesomeIcon className="" icon={faTag} />
                        </div>
                        <div className="stats-value">0</div>
                        <div className="stats-hd product-view-hd">
                          Product Views
                        </div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon">
                          <FontAwesomeIcon
                            className="text-danger"
                            icon={faBoxOpen}
                          />
                        </div>
                        <div className="stats-value">
                          {getReviewById?.payload?.message[0]?.trans && getReviewById?.payload?.message[0]?.trans.length
                            ? getReviewById?.payload?.message[0]?.trans[0]
                              ?.number_of_orders
                            : "0"}
                        </div>
                        <div className="stats-hd">Orders</div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon usd-txt">USD</div>
                        <div className="stats-value">
                          {getReviewById?.payload?.message[0]?.trans && getReviewById?.payload?.message[0]?.trans.length
                            ? numeral(
                              getReviewById?.payload?.message[0]?.trans[0]
                                ?.total_amount
                            ).format("0,0.0'")
                            : "0"}
                        </div>
                        <div className="stats-hd">Sales</div>
                      </div>
                    </div>
                    <div className="control-video-box-main recorded ">
                      {/* <div className="box-left">Data</div> */}
                      <div className="box-right">
                        <div className="video_preview " id="video_preview">
                          {myPlayer}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="recorded-right"></div>
                </div>


              </div>
              <div
                className={`control-boxes-box tabs-control-room-main 
                ${mediaHeight ? "mediaheight" : ""}
                `}
                style={{ height: height }}
              >
                <Tabs
                  className="tabs-control-room chat-tab-data"
                  defaultActiveKey="3"
                  onChange={onKeyChange}
                >
                  <TabPane tab="Chat" key="1">
                    <RecordedShowChat
                      type='review'
                      chat={chat}
                      chatLoading={chatLoading}
                      height={height}
                    />
                  </TabPane>
                  <TabPane tab="Products" key="3">
                    {/* <h4 className="hd-tab">Products Listing</h4> */}

                    <div
                      className="control-products-listing-main-wrapper scrollbar-style overflow-y-auto"
                      style={{ maxHeight: height - 90 }}
                    >
                      {getReviewById?.payload?.message[0]?.products
                        .length ? (
                        getReviewById?.payload?.message[0]?.products.map(
                          (item, i) => {
                            return (
                              <>
                                <div className="control-products-listing-main ">
                                  <div className="control-products-listing">
                                    <div className="control-product-image">
                                      <img
                                        src={item.mediaUrl}
                                        alt={item.title}
                                        className=""
                                      />
                                    </div>

                                    <div className="control-product-content">
                                      <div className="product-left">
                                        <div
                                          className="control-product-title"
                                          title={item.title}
                                        >
                                          {item.title}
                                        </div>
                                        <div className="control-product-price">
                                          ${item.price}
                                        </div>
                                      </div>
                                      <div className="product-right">
                                        <div className="product-quantity">
                                          {/* Qty: 150 */}{" "}
                                        </div>
                                        {/* <div className="control-product-copy">
                                      <button
                                        class="default-btn small-btn fw-normal"
                                        onClick={() =>
                                          productUrl(item.ProductUrl)
                                        }
                                      >
                                        Copy Url
                                      </button>
                                    </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <Link to="#" className="block-link"></Link> */}
                                </div>
                              </>
                            );
                          }
                        )
                      ) : (
                        <h5 className="text-center">No Product</h5>

                      )}
                    </div>
                  </TabPane>

                </Tabs>
              </div>
            </div>
          </>
        )}
      </>
    );
  } else {
    return (
      <span className="spinclass loader-center">
        <Spin size="large" />
      </span>
    );
  }
}
export default RecordedReview;
