import {
  GET_SHOPIFY_DETAIL_REQUEST,
  GET_SHOPIFY_DETAIL_SUCCESS,
  GET_SHOPIFY_DETAIL_ERROR,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  POST_SHOPIFY_REQUEST,
  POST_SHOPIFY_SUCCESS,
  POST_SHOPIFY_ERROR,
  POST_SALESFORCE_REQUEST,
  POST_SALESFORCE_SUCCESS,
  POST_SALESFORCE_ERROR,
  GET_SALESFORCE_DETAIL_REQUEST,
  GET_SALESFORCE_DETAIL_SUCCESS,
  GET_SALESFORCE_DETAIL_ERROR
} from '../types/types';
import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'));
export const getShopifyDetail = () => (dispatch) => {
  dispatch({ type: GET_SHOPIFY_DETAIL_REQUEST });

  return axios({
    method: 'GET',
    url: `users/receive/shopify`,
    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_SHOPIFY_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SHOPIFY_DETAIL_ERROR,
        payload: error.response,
      });
    });
};

export const getSalesForceDetail = () => (dispatch) => {
  dispatch({ type: GET_SALESFORCE_DETAIL_REQUEST });

  return axios({
    method: 'GET',
    url: `users/receive/sfcc`,
    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_SALESFORCE_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SALESFORCE_DETAIL_ERROR,
        payload: error.response,
      });
    });
};
console.log('');
export const getProducts = (TOKEN) => (dispatch) => {
  dispatch({ type: GET_PRODUCT_REQUEST });

  return axios({
    method: 'POST',
    url: `ecom/getproducts`,
    // data: {
    // 	parent_id: id,
    // },
    headers: { Authorization: `Bearer ${TOKEN  ? TOKEN : token}` }
  })
    .then((response) => {
      return dispatch({
        type: GET_PRODUCT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_PRODUCT_ERROR,
        payload: error.response,
      });
    });
};

export const postShopify = (data) => (dispatch) => {
  dispatch({ type: POST_SHOPIFY_REQUEST });
  return axios({
    method: 'POST',
    url: `users/revise/shopify`,
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_SHOPIFY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_SHOPIFY_ERROR,
        payload: error.response,
      });
    });
};



export const postSalesForce = (data) => async (dispatch) => {

  let promise = new Promise((resolve, reject) => {
    axios
      .post(`users/revise/sfcc`, {
          host: data.host,
          site_id: data.site_id,
          api_version: "v22_6",
          client_id: data.client_id,
          client_secret: data.client_secret,
          am_username: data.am_username,
          am_password: data.am_password

      })
      .then((res) => {
        resolve(res.data);
        dispatch({
          type: POST_SALESFORCE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((res) => {
        resolve(res?.response?.data)
        console.log(res,'sss');
        dispatch({
          type: POST_SALESFORCE_ERROR,
          payload: res?.response?.data,
        });
       
      });
  });
  return promise;
};